import { EventEmitter } from "events";
import moment from "moment";


class Queue {
    #head = 0;
    #tail = 0;
    #id = "";
    constructor(id) {
        this.#id = id;
    }
    get Id() {
        return this.#id;
    }
    get Size() {
        return this.#tail - this.#head;
    }
    get IsEmpty() {
        return this.#head === this.#tail;
    }
    enqueue(item) {
        this[this.#tail] = item;
        this.#tail++;
    }
    dequeue() {
        if (this.#head === this.#tail) {
            return undefined;
        }
        const item = this[this.#head];
        delete this[this.#head];
        this.#head++;
        return item;
    }
    peak() {
        if (this.#head === this.#tail) {
            return undefined;
        }
        return this[this.#head];
    }
}

class WebSocketClient {
    #instance;
    #uri;
    #reconnectInterval;
    #maxReconnectInterval;
    #isClosedManually;
    #subscribers;
    #callFunc = {};
    #event = new EventEmitter();
    #ack = {};
    #isReady = false;
    #methodIds = {};
    /**
     * @type {Queue}
     */
    #queue = new Queue("pool");
    constructor(uri) {
        this.#uri = uri;
        this.#reconnectInterval = 1000; // Initial reconnect interval in ms
        this.#maxReconnectInterval = 30000; // Maximum reconnect interval in ms
        this.#isClosedManually = false;
        this.#subscribers = {};
        this.connect();
        this.runPool();
    }
    /**
     * @returns {EventEmitter}
     */
    get Event() {
        return this.#event;
    }
    /**
     * @returns {WebSocket}
     */
    get Instance() {
        return this.#instance;
    }
    runPool() {
        setTimeout(() => {
            if (!this.#queue.IsEmpty && this.#isReady) {
                // this.#isReady && this.executePool(this.#queue.dequeue());
                this.#instance.send(JSON.stringify(this.#queue.dequeue()));
            }
            this.runPool();
        }, 200);
    }
    executePool(msg) {
        if (!this.#ack[msg.id] && this.#isReady) {
            this.#ack[msg.id] = msg;
            this.#instance.send(JSON.stringify(msg));
        }
    }
    connect() {
        this.#instance = new WebSocket(this.#uri);
        this.#instance.onopen = () => {
            this.#reconnectInterval = 1000; // Reset the reconnect interval on successful connection
            this.#isReady = true;
            for (const subscriber in this.#subscribers) {
                this.#instance.send(JSON.stringify({ event: "subscribe", channel: subscriber }));
            }
            console.log("WebSocket connected");
        };
        this.#instance.onmessage = (e) => {
            const message = JSON.parse(e.data);
            const parsed = Object.assign({}, message);
            const event = message.event;
            delete parsed.event;
            this.parseMessage(event, parsed);
        };
        this.#instance.onclose = () => {
            console.log("WebSocket disconnected");
            if (!this.#isClosedManually) {
                this.reconnect();
            }
            this.#isReady = false;
        };
        this.#instance.onerror = (err) => {
            console.error("WebSocket encountered error:", err.message, "Closing socket");
            this.#instance.close();
            this.#isReady = false;
        };
    }
    reconnect() {
        console.log(`Reconnecting in ${this.#reconnectInterval / 1000} seconds...`);
        setTimeout(() => {
            this.#reconnectInterval = Math.min(this.#reconnectInterval * 2, this.#maxReconnectInterval); // Exponential backoff
            this.connect();
        }, this.#reconnectInterval);
    }
    close() {
        this.#isClosedManually = true;
        this.#instance.close();
    }
    send(event, data) {
        if (!data) data = {};
        if (data && !data.id) data.id = `${event}-${moment().valueOf()}`;
        this.#queue.enqueue({ ...data, event: event });
    }
    callFunc(methodId, data) {
        return new Promise((resolve, reject) => {
            const msg = {
                event: "method",
                methodId: methodId,
                data: data,
                id: `${methodId}-${moment().valueOf()}`
            };
            if (this.#methodIds[methodId])
                return reject("method already running");
            this.#methodIds[methodId] = msg.id;
            this.#callFunc[msg.id] = methodId;
            this.#event.once(msg.id, (response) => {
                delete this.#methodIds[methodId];
                if (response.error) {
                    reject(response.error);
                } else {
                    resolve(response.data);
                }
            });
            this.#queue.enqueue(msg);
        });
    }
    subscribe(event, callback) {
        if (Array.isArray(event)) {
            event = event.join(",");
        }
        if (!this.#subscribers[event]) {
            this.#subscribers[event] = callback;
            this.send("subscribe", { channel: event });
        }
    }
    unsubscribe(event) {
        if (this.#subscribers[event]) {
            delete this.#subscribers[event];
            this.send("unsubscribe", { channel: event });
        }
    }
    parseMessage(event, data) {
        switch (event) {
            case "ack":
                if (data && data.id && this.#ack[data.id]) {
                    delete this.#ack[data.id];
                }
                break;
            default:
                if (this.#subscribers[event]) {
                    this.#subscribers[event](data);
                }
                if (data && data.id && this.#callFunc[data.id]) {
                    this.#event.emit(data.id, data);
                    delete this.#callFunc[data.id];
                }
                break;
        }
    }
}

// const ws = new WebSocketClient("ws://localhost:3000");

export default WebSocketClient;

import React from "react";
import Main from "../Main";
import { PageNotfound } from "../../devlink/PageNotfound";

function Notfound() {
    return (
        <Main>
            <PageNotfound />
        </Main>
    );
}

export default Notfound;

"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { LeadinfoItem } from "./LeadinfoItem";
import { Taglead } from "./Taglead";
import * as _utils from "./utils";
import _styles from "./Leadinfo.module.css";

export function Leadinfo({
  as: _Component = _Builtin.Block,
  leadAvatar = "images/66968578d5889c78730e31f3_avatar_01.png",
  leadName = "",
  leadPosition = "",
  ...props
}) {
  const isAddressExist= props.address && props.address.length !== 0 
  const primaryAddressObj =  isAddressExist ? props.address.find((address) => address.primary === true) : {}
  const primaryAddress = isAddressExist ? Object.entries(primaryAddressObj).map(([key, value]) =>
    key !== 'primary' ? value : ''
  ).join(' ') : ""
  const primaryNumberObj =  props.phoneNumbers && props.phoneNumbers.length !==0 ? props.phoneNumbers.find((phone) => phone.primary === true) : ""
  const primaryEmailObj =  props.emails && props.emails.length !==0 ? props.emails.find((email) => email.primary === true) : ""
  
  return (
    <_Component className={_utils.cx(_styles, "card-leadinfo")} tag="div">
      <_Builtin.Block className={_utils.cx(_styles, "leadinfo-top")} tag="div">
        <_Builtin.Block
          className={_utils.cx(_styles, "leadinfo-avatar")}
          tag="div"
        >
          <_Builtin.Image
            loading="lazy"
            width="auto"
            height="auto"
            alt=""
            src={leadAvatar}
          />
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "leadinfo-textcontents")}
          tag="div"
        >
          <_Builtin.Heading
            className={_utils.cx(_styles, "phoneheader_text")}
            tag="h3"
          >
            {leadName}
          </_Builtin.Heading>
          <_Builtin.Block className={_utils.cx(_styles, "p-style1")} tag="div">
            {leadPosition}
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "leadinfo-btncontainer")}
            tag="div"
          >
            <_Builtin.Link
              className={_utils.cx(_styles, "phonetop_iconbtn", "mr-5")}
              button={false}
              block="inline"
              options={{
                href: "#",
              }}
            >
              <_Builtin.Image
                className={_utils.cx(_styles, "image-100")}
                width="auto"
                height="auto"
                loading="lazy"
                alt=""
                src="images/66979e4cf9a013f97051ba41_leadinfo_01.svg"
              />
            </_Builtin.Link>
            <_Builtin.Link
              className={_utils.cx(_styles, "phonetop_iconbtn", "mr-5")}
              button={false}
              block="inline"
              options={{
                href: "#",
              }}
            >
              <_Builtin.Image
                className={_utils.cx(_styles, "image-100")}
                width="auto"
                height="auto"
                loading="lazy"
                alt=""
                src="images/66979e4cff41529e4d881400_leadinfo_02.svg"
              />
            </_Builtin.Link>
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "option-container")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "phonetop_iconbtn")}
            tag="div"
          >
            <_Builtin.Image
              className={_utils.cx(_styles, "image-100")}
              width="auto"
              height="auto"
              loading="lazy"
              alt=""
              src="images/66968578d5889c78730e31aa_icon_threedots.svg"
            />
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block className={_utils.cx(_styles, "leadinfo-body")} tag="div">
        <_Builtin.Block
          className={_utils.cx(_styles, "leadinfo-item-header")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "leadinfo-hd-text")}
            tag="div"
          >
            {"Personal Contact"}
          </_Builtin.Block>
        </_Builtin.Block>
        <LeadinfoItem label="Work Email" data={primaryEmailObj ? primaryEmailObj.email : ""} />
        <LeadinfoItem label="Work Phone" data={primaryNumberObj ? primaryNumberObj.number : ""} />
        <LeadinfoItem label="Work Address" data={primaryAddress} />
        <_Builtin.Block
          className={_utils.cx(_styles, "leadinfo-item-header", "mt-20")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "leadinfo-hd-text")}
            tag="div"
          >
            {"Tags"}
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "leadtags-contain")}
          tag="div"
        >
          {props.tags && props.tags !== 0 && props.tags.map((tag, index) => (<Taglead key={index} tagtext={tag} />))}
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}

import React from "react";
import { RotatingLines } from "react-loader-spinner";
export function LoaderChat({ height = "25", width = "25" }) {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <RotatingLines
                visible={true}
                height={height}
                width={width}
                strokeColor="#6d42f8"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    );
}

"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./PageNotfound.module.css";

export function PageNotfound({ as: _Component = _Builtin.Block }) {
  return (
    <_Component className={_utils.cx(_styles, "notfound-wrap")} tag="div">
      <_Builtin.Block className={_utils.cx(_styles, "nf-404-brand")} tag="div">
        <_Builtin.Image
          width="auto"
          height="auto"
          loading="lazy"
          alt=""
          src="images/669a8dabcaba2db1bf9682ee_logo-full.svg"
        />
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "notfound-page-content")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "nf-404page-image")}
          tag="div"
        >
          <_Builtin.Image
            width="1456"
            height="auto"
            loading="auto"
            alt=""
            src="images/669a92a327793752bb776354_notfound.png"
          />
        </_Builtin.Block>
        <_Builtin.Heading
          className={_utils.cx(_styles, "nf-404-hd-text")}
          tag="h2"
        >
          {"Oops! Our little robot couldn't find that page."}
        </_Builtin.Heading>
        <_Builtin.Block
          className={_utils.cx(_styles, "nf-404page-paragraph")}
          tag="div"
        >
          <_Builtin.Block tag="div">
            {
              "The page you were looking for doesn't exist. It might have been removed, had its name changed, or is temporarily unavailable."
            }
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "nf-404page-btn-div")}
          tag="div"
        >
          <_Builtin.Link
            className={_utils.cx(_styles, "btn")}
            button={true}
            block=""
            options={{
              href: "/",
            }}
          >
            {"Return to Homepage"}
          </_Builtin.Link>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}

"use client";
import React, { useEffect, useRef, useState } from "react";
import * as _Builtin from "./_Builtin";
import { Bubbleoutbound } from "./Bubbleoutbound";
import { Bubbleinbound } from "./Bubbleinbound";
import * as _utils from "./utils";
import _styles from "./Conversation.module.css";
import { Loader } from "../component/common/Loader";
import { LoaderChat } from "../component/common/LoaderChat";

export function Conversation({
  as: _Component = _Builtin.Block,
  datedivide = "Today",
  textarea = {},
  btnsend = {},
  handleSendingMessage = () => { },
  data = [],
  handleClickInteraction = () => { },
  handleActiveTab = () => { },
  isFetchingInteractions = false,
  loadMore = () => { },
  isLoadingMore = false
}) {
  const chatContainerRef = useRef(null);
  const messageRef = useRef(null);

  const [isAtBottom, setIsAtBottom] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
        setIsAtBottom(scrollTop + clientHeight === scrollHeight);
      }
    };

    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      handleScroll();
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isFetchingInteractions]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth"
      });
    }
  };

  const handleScroll = () => {
    if (chatContainerRef.current) {
      if (chatContainerRef.current.scrollTop === 0) {
        loadMore();
      }
    }
  };

  useEffect(() => {
    const currentRef = chatContainerRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [data]);

  const handleMessage = () => {
    const message = messageRef.current.value;
    if (message) {
      handleSendingMessage(message);
      messageRef.current.value = "";
    }
  }

  return (
    <_Component className={_utils.cx(_styles, "conversation-wrap")} tag="div">
      <_Builtin.Block
        className={_utils.cx(_styles, "conversationwrap")}
        tag="div"
      >
       {isFetchingInteractions && !isLoadingMore ? <Loader /> :
        <_Builtin.Block
          className={_utils.cx(_styles, "conversation")}
          tag="div"
          ref={chatContainerRef}
        >
          {isLoadingMore && <LoaderChat />}
          <_Builtin.Block
            className={_utils.cx(_styles, "datedivider")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "datedivider-line")}
              tag="div"
            />
            <_Builtin.Block
              className={_utils.cx(_styles, "datedivider-text")}
              tag="div"
            >
              {datedivide}
            </_Builtin.Block>
            <_Builtin.Block
              className={_utils.cx(_styles, "datedivider-line")}
              tag="div"
            />
          </_Builtin.Block>
          {(data && typeof data === "object" && data.length !== 0) && data.map((item, index) => {
            if (item.direction === "outbound") {
              return (
                <Bubbleoutbound
                  key={index}
                  bubbletext={item.content}
                  time={item.createdAt}
                  handleClickInteraction={handleClickInteraction}
                  handleActiveTab={handleActiveTab}
                  {...item}
                />
              );
            } else {
              if (item && item.content)
              return (
                <Bubbleinbound
                  key={index}
                  bubbletext={item.content}
                  time={item.createdAt}
                  handleClickInteraction={handleClickInteraction}
                  handleActiveTab={handleActiveTab}
                  {...item}
                />
              );
            }
          })}
        </_Builtin.Block>
       }
      </_Builtin.Block>
      {!isAtBottom && <_Builtin.Block
        tag="div"
        style={{
          backgroundColor: "blue",
          borderRadius: "10px 10px 0 0",
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "10px"
        }}
      >
        <_Builtin.Link
          button={false}
          block="inline"
          options={{
            href: "#",
          }}
          style={{ fontSize: "12px", color: "white" }}
          onClick={scrollToBottom}
        >
          Latest Message
          <_Builtin.Image src="/images/caret-down-fill.svg" />
        </_Builtin.Link>
      </_Builtin.Block> }
      <_Builtin.Block className={_utils.cx(_styles, "smsreply")} tag="div">
        <_Builtin.Block
          className={_utils.cx(_styles, "smsreply_left")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "smsreply_file", "mb-2")}
            tag="div"
          >
            <_Builtin.Image
              className={_utils.cx(_styles, "image-100")}
              width="auto"
              height="auto"
              loading="lazy"
              alt=""
              src="images/66968578d5889c78730e31da_sms_01.svg"
            />
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "smsreply_file")}
            tag="div"
          >
            <_Builtin.Image
              className={_utils.cx(_styles, "image-100")}
              width="auto"
              height="auto"
              loading="lazy"
              alt=""
              src="images/66968578d5889c78730e31dd_sms_02.svg"
            />
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "sms_textfield-container")}
          tag="div"
        >
          <_Builtin.FormTextarea
            className={_utils.cx(_styles, "sms_textarea")}
            name="field-3"
            maxLength={5000}
            data-name="Field 3"
            placeholder="Type your message"
            required={false}
            autoFocus={false}
            id="field-3"
            ref={messageRef}
            {...textarea}
          />
          <_Builtin.Block
            className={_utils.cx(_styles, "sms_senddiv")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "sms-send")}
              tag="div"
              onClick={handleMessage}
              {...btnsend}
            >
              <_Builtin.Image
                className={_utils.cx(_styles, "image-100")}
                width="auto"
                height="auto"
                loading="lazy"
                alt=""
                src="images/66968578d5889c78730e31e5_sms_03.svg"
              />
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}

"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { Dialer } from "./Dialer";
import * as _utils from "./utils";
import _styles from "./Inboxcol4.module.css";

export function Inboxcol4({ as: _Component = _Builtin.Block, dialerHandler = () => { }, callStatus = "",
  handleHangUp = () => { }, incomingCallInfo = {}, dialerLoading = false, callErrorMsg = "", openModalKeypad = () => { }
}) {
  return (
    <_Component className={_utils.cx(_styles, "dashboard-col4")} tag="div">
      <Dialer dialerHandler={dialerHandler} callStatus={callStatus} handleHangUp={handleHangUp}
        incomingCallInfo={incomingCallInfo} dialerLoading={dialerLoading} callErrorMsg={callErrorMsg} openModalKeypad={openModalKeypad}
      />
    </_Component>
  );
}

"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./Socialitem.module.css";

export function Socialitem({
  as: _Component = _Builtin.Block,
  avatar = "images/66968578d5889c78730e31f3_avatar_01.png",
  logoSocial = "images/66968578d5889c78730e31c4_social_fb.svg",
  username = "Patrick Jenkins",
  userhandle = "@patjenkins",
  time = "2hrs ago",
  textcontent = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
}) {
  return (
    <_Component className={_utils.cx(_styles, "socials_item")} tag="div">
      <_Builtin.Block
        className={_utils.cx(_styles, "socials_item-top")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "socials_item-topleft")}
          tag="div"
        >
          <_Builtin.Block className={_utils.cx(_styles, "avatar")} tag="div">
            <_Builtin.Image
              className={_utils.cx(_styles, "avatar_image")}
              width="auto"
              height="auto"
              loading="lazy"
              alt=""
              src={avatar}
            />
            <_Builtin.Block
              className={_utils.cx(_styles, "social_media")}
              tag="div"
            >
              <_Builtin.Image
                width="auto"
                height="auto"
                loading="lazy"
                alt=""
                src={logoSocial}
              />
            </_Builtin.Block>
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "socials_headertextcontent")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "username")}
              tag="div"
            >
              {username}
            </_Builtin.Block>
            <_Builtin.Block
              className={_utils.cx(_styles, "previewtext")}
              tag="div"
            >
              {userhandle}
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "socials_item-topright")}
          tag="div"
        >
          <_Builtin.Block className={_utils.cx(_styles, "datetext")} tag="div">
            {time}
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "socials_item-body")}
        tag="div"
      >
        <_Builtin.Paragraph className={_utils.cx(_styles, "p-style1")}>
          {textcontent}
        </_Builtin.Paragraph>
      </_Builtin.Block>
    </_Component>
  );
}

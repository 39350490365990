"use client";
import React, { useState, useEffect } from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./MatchcolumnRow.module.css";
import Utilities from "../common/Utilities";
import { useButtonContext } from "../contexts/ButtonContext";

export function MatchcolumnRow({
  as: _Component = _Builtin.Block,
  yourtable = {},
  willbecome = {},
  text = "",
  leadsData = [],
  header = {},
  parentkey = "",
  handleMatchColumns = () => {},
  AddMatchColumnHeader = () => { },
  AddNewHeader = () => {},
}) {
  const [selectedValue, setSelectedValue] = useState('');
  const [hasManuallySelected, setHasManuallySelected] = useState(false);

  const { clickCount } = useButtonContext();

  useEffect(() => {
    if (clickCount) {
      setSelectedValue('');
      if (leadsData && header.headerName) {
        let highestSimilarity = 0;
        let mostSimilarKey = '';
  
        Object.keys(leadsData).forEach(key => {
          const similarity = Utilities.calculateSimilarity(header.headerName, key);
          if (similarity > highestSimilarity && similarity > 0.7) { // 70% similarity threshold
            highestSimilarity = similarity;
            mostSimilarKey = key;
          }
        });
  
        if (mostSimilarKey && leadsData[mostSimilarKey]) {
          setSelectedValue(leadsData[mostSimilarKey].key);
          AddMatchColumnHeader(header.headerName, leadsData[mostSimilarKey].key);
        }
      }
    }
  }, [clickCount]);

  useEffect(() => {
    if (leadsData && header.mapId && !hasManuallySelected) {
      const initialSelected = Object.entries(leadsData).find(([key, value]) => value.key === header.mapId);
      if (initialSelected) {
        setSelectedValue(initialSelected[1].key);
      }
    }
  }, [leadsData, header.mapId, hasManuallySelected]);

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
    setHasManuallySelected(true);
    handleMatchColumns(e, header.headerName);
  };

  const showAddButton = leadsData && Object.values(leadsData).some(value => value.key === parentkey);

  return (
    <_Component className={_utils.cx(_styles, "matchcolumn_row")} tag="div">
      <_Builtin.Block className={_utils.cx(_styles, "matchcolumn-col")} tag="div">
        {showAddButton ? null : (
          <_Builtin.Block 
            className={_utils.cx(_styles, "btn-add")} 
            tag="div" 
            onClick={() => AddNewHeader(header.headerName, "auto")}
          >
            <_Builtin.Image
              loading="lazy"
              width="auto"
              height="auto"
              alt=""
              src="images/66969177fb74722e775dde42_inbox_01.svg"
            />
          </_Builtin.Block>
        )}
        <_Builtin.FormTextInput
          className={_utils.cx(_styles, "text-field", "mb-0")}
          autoFocus={false}
          maxLength={256}
          name="name-2"
          data-name="Name 2"
          type="text"
          defaultValue={header.headerName}
          disabled={false}
          required={false}
          id="name-2"
          {...yourtable}
        />
      </_Builtin.Block>
      <_Builtin.Block className={_utils.cx(_styles, "matcholumn-iconcontainer")} tag="div">
        <_Builtin.Block className={_utils.cx(_styles, "matcholumn-icon")} tag="div">
          <_Builtin.Image
            className={_utils.cx(_styles, "image-100")}
            loading="lazy"
            width="auto"
            height="auto"
            alt=""
            src="images/66968578d5889c78730e31bd_icon-match.svg"
          />
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block className={_utils.cx(_styles, "matchcolumn-col")} tag="div">
        <select
          className={_utils.cx(_styles, "select-field")}
          name="field-7"
          data-name="Field 7"
          required={true}
          value={selectedValue}
          onChange={handleSelectChange}
          id="field-7"
        >
          <option value="">Select Column...</option>
          {leadsData && Object.entries(leadsData).map(([key, value]) => (
            <option key={key} value={value.key} disabled={value.isSelected}>
              {key}
            </option>
          ))}
        </select>
      </_Builtin.Block>
    </_Component>
  );
}

class ClientWatcher {
    #wss;
    #transcriptionId;
    #inboxId;
    #interactionId;
    #lastInboxId;
    #lastInteractionId;
    #lastTranscriptionId;
    constructor(wss) {
        this.#wss = wss;
    }
    /**
     * @returns {import("./services/websocket").default}
     */
    get Instance() {
        return this.#wss;
    }
    get InboxId() {
        return this.#inboxId;
    }
    async fetchInbox() {
        try {
            return await this.Instance.callFunc("fetchInbox", { lastInboxId: this.#lastInboxId });
        } catch (error) {
            throw new Error(error);
        }

    }
    async fetchInteraction({ inboxId, last }) {
        try {
            return await this.Instance.callFunc("fetchInteraction", { inboxId, last });
        } catch (error) {
            throw new Error(error);
        }
    }
    async fetchTranscription(interactionId) {
        try {
            return await this.Instance.callFunc("fetchTranscription", { interactionId });
        } catch (error) {
            throw new Error(error);
        }
    }
    async fetchLead(leadId) {
        try {
            return await this.Instance.callFunc("fetchLead", { leadId });
        } catch (error) {
            throw new Error(error);
        }
    }
    async fetchAllLead({ last }) {
        try {
            return await this.Instance.callFunc("fetchAllLead", { last });
        } catch (error) {
            throw new Error(error);
        }
    }
    async fetchLeadHistory(leadId) {
        try {
            return await this.Instance.callFunc("fetchLeadHistory", { leadId });
        } catch (error) {
            throw new Error(error);
        }
    }
    setInboxId(inboxId) {
        this.#inboxId = inboxId;
    }
    setTranscriptionId(transcriptionId) {
        this.#transcriptionId = transcriptionId;
    }
    setInteractionId(interactionId) {
        this.#interactionId = interactionId;
    }

    async resetUnreadCount(inbox) {
        try {
            await this.Instance.callFunc("resetUnreadCount", inbox);
        } catch (error) {
            throw new Error(error);
        }
    }
    updateData(lead) {
        this.#wss.callFunc("updateData", lead);
    }
}

export default ClientWatcher;
"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { Inboxcol1 } from "./Inboxcol1";
import { Inboxcol2 } from "./Inboxcol2";
import { Inboxcol3 } from "./Inboxcol3";
import { Inboxcol4 } from "./Inboxcol4";
import { Controldiv } from "./Controldiv";
import * as _utils from "./utils";
import _styles from "./Inbox.module.css";

export function Inbox({ as: _Component = _Builtin.Block, dialerHandler = () => { }, interactionsData = [], dataCollectHandler = () => { },
  handleSendingMessage = () => { }, transcriptionData = [], handleTranscriptMessage = () => { }, handleClickInteraction = () => { }, callStatus = "",
  handleHangUp = () => { }, inboxes = [], handleClickUserInbox = () => { }, currentLead = {}, incomingCallInfo = {}, isFetchingInteractions = false,
  isFetchingTanscriptions = false, loadMore = () => { }, isLoadingMore = false, baseInfo = {}, dialerLoading = false, leadLoading = false, callErrorMsg = "",
  openModalKeypad = () => { }, interactionId = ""
}) {
  return (
    <_Component className={_utils.cx(_styles, "inbox-container")} tag="div">
      <_Builtin.TabsWrapper
        className={_utils.cx(_styles, "convo-tabs")}
        current="Tab 1"
        easing="ease"
        fadeIn={300}
        fadeOut={100}
      >
        <_Builtin.TabsMenu
          className={_utils.cx(_styles, "convo-tabsmenu", "leads")}
          tag="div"
        >
          <_Builtin.TabsLink
            className={_utils.cx(_styles, "convo-tablink")}
            data-w-tab="Tab 1"
            block="inline"
          >
            <_Builtin.Block tag="div">{"Open"}</_Builtin.Block>
          </_Builtin.TabsLink>
          <_Builtin.TabsLink
            className={_utils.cx(_styles, "convo-tablink")}
            data-w-tab="Tab 2"
            block="inline"
          >
            <_Builtin.Block tag="div">{"Called"}</_Builtin.Block>
          </_Builtin.TabsLink>
          <_Builtin.TabsLink
            className={_utils.cx(_styles, "convo-tablink")}
            data-w-tab="Tab 3"
            block="inline"
          >
            <_Builtin.Block tag="div">{"Follow-up"}</_Builtin.Block>
          </_Builtin.TabsLink>
          <_Builtin.TabsLink
            className={_utils.cx(_styles, "convo-tablink")}
            data-w-tab="Tab 4"
            block="inline"
          >
            <_Builtin.Block tag="div">{"Cold Leads"}</_Builtin.Block>
          </_Builtin.TabsLink>
        </_Builtin.TabsMenu>
        <_Builtin.TabsContent
          className={_utils.cx(_styles, "convo-tabscontent", "noborder")}
          tag="div"
        >
          <_Builtin.TabsPane
            className={_utils.cx(_styles, "tabpane")}
            tag="div"
            data-w-tab="Tab 1"
          >
            <_Builtin.FormWrapper>
              <_Builtin.FormForm
                name="email-form-3"
                data-name="Email Form 3"
                method="get"
                id="email-form-3"
              >
                <_Builtin.Block
                  className={_utils.cx(_styles, "dashboard-section")}
                  tag="div"
                >
                  <Inboxcol1 data={inboxes} handleClickUserInbox={handleClickUserInbox} currentLead={currentLead} />
                  <Inboxcol2 data={interactionsData} handleSendingMessage={handleSendingMessage} transcriptionData={transcriptionData}
                    handleTranscriptMessage={handleTranscriptMessage} handleClickInteraction={handleClickInteraction}
                    baseInfo={baseInfo} isFetchingInteractions={isFetchingInteractions} isFetchingTanscriptions={isFetchingTanscriptions}
                    loadMore={loadMore} isLoadingMore={isLoadingMore} interactionId={interactionId}
                  />
                  <Inboxcol3 dataCollectHandler={dataCollectHandler} currentLead={currentLead} leadLoading={leadLoading} />
                  <Inboxcol4 dialerHandler={dialerHandler} callStatus={callStatus} handleHangUp={handleHangUp} openModalKeypad={openModalKeypad}
                    incomingCallInfo={incomingCallInfo} dialerLoading={dialerLoading} callErrorMsg={callErrorMsg}
                  />
                </_Builtin.Block>
              </_Builtin.FormForm>
              <_Builtin.FormSuccessMessage>
                <_Builtin.Block tag="div">
                  {"Thank you! Your submission has been received!"}
                </_Builtin.Block>
              </_Builtin.FormSuccessMessage>
              <_Builtin.FormErrorMessage>
                <_Builtin.Block tag="div">
                  {"Oops! Something went wrong while submitting the form."}
                </_Builtin.Block>
              </_Builtin.FormErrorMessage>
            </_Builtin.FormWrapper>
          </_Builtin.TabsPane>
          <_Builtin.TabsPane tag="div" data-w-tab="Tab 2" />
          <_Builtin.TabsPane tag="div" data-w-tab="Tab 3" />
          <_Builtin.TabsPane tag="div" data-w-tab="Tab 4" />
        </_Builtin.TabsContent>
      </_Builtin.TabsWrapper>
      <Controldiv />
    </_Component>
  );
}

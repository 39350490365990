"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./NotificationItem.module.css";

export function NotificationItem({
  as: _Component = _Builtin.Block,
  avatar = "images/66968578d5889c78730e31f3_avatar_01.png",
  activityIcon = "images/66968578d5889c78730e31e9_icon_email_white.svg",
  notificationText = " sent an email",
  timeText = "2 min ago",
}) {
  return (
    <_Component className={_utils.cx(_styles, "notification-item")} tag="div">
      <_Builtin.Block
        className={_utils.cx(_styles, "avatar", "larger")}
        tag="div"
      >
        <_Builtin.Image
          className={_utils.cx(_styles, "avatar_image")}
          loading="lazy"
          width="auto"
          height="auto"
          alt=""
          src={avatar}
        />
        <_Builtin.Block
          className={_utils.cx(_styles, "notif_activityicon")}
          tag="div"
        >
          <_Builtin.Image
            loading="lazy"
            width="auto"
            height="auto"
            alt=""
            src={activityIcon}
          />
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "notification-textcontent")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "p-style1", "larger")}
          tag="div"
        >
          <strong>Patrick Jenkins</strong>
          {notificationText}
        </_Builtin.Block>
        <_Builtin.Block className={_utils.cx(_styles, "previewtext")} tag="div">
          {timeText}
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}

import React, { useEffect, useState } from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./Datacollect.module.css";
import { Loader } from "../component/common/Loader";
import Utilities from "../common/Utilities";
import moment from "moment";
import { LEADS_PROTECTIVE_FIELDS } from "../common/const";

export function Datacollect({ as: _Component = _Builtin.Block, dataCollectHandler = () => { }, currentLead = {},
  leadLoading = false
}) {
  const [note, setNote] = useState("");
  const [fields, setFields] = useState({});
  const skipFields = ["id", "notes"];
  const updates = Object.assign({}, currentLead);

  const updateLeads = (currentLead) => {
    if (!Utilities.isEmptyObject(currentLead)) {
      const updatedFields = {};
      Object.keys(currentLead).forEach((key) => {
        const value = currentLead[key];
        if (value !== null && value !== undefined) {
          if (Array.isArray(value)) {
            // if (value.length > 0) {
            if (typeof value[0] === 'object') {
              updatedFields[key] = {
                value: value.filter(item => item !== null && typeof item === 'object'),
                isEditing: false
              };
            } else {
              updatedFields[key] = {
                value: value.filter(item => item !== null && item !== undefined),
                isEditing: new Array(value.length).fill(false)
              };
            }
            // }
          } else if (typeof value === 'object') {
            updatedFields[key] = {
              value: value,
              isEditing: false
            };
          } else if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
            updatedFields[key] = {
              value: value,
              isEditing: false
            };
          }
        }
      });
      setFields(updatedFields);
      setNote(currentLead.notes || "");
    }
  };

  useEffect(() => {
    updateLeads(currentLead);
  }, [currentLead]);

  const handleSave = () => {
    const saveData = {};
    Object.keys(currentLead).forEach((key) => {
      saveData[key] = fields[key].value;
    });
    saveData.notes = note;
    if (updates.meta) saveData.meta = updates.meta;
    dataCollectHandler(saveData);
  };

  const handleNotes = (e) => {
    e.preventDefault();
    setNote(e.target.value);
  };

  const handleIconClick = (field, index = null) => {
    setFields((prevFields) => {
      if (index !== null) {
        const newEditingState = [...(prevFields[field].isEditing || [])];
        newEditingState[index] = true;
        return {
          ...prevFields,
          [field]: { ...prevFields[field], isEditing: newEditingState }
        };
      } else {
        return {
          ...prevFields,
          [field]: { ...prevFields[field], isEditing: true }
        };
      }
    });
  };

  const handleBlur = (field, newValue, index = null) => {
    setFields((prevFields) => {
      if (index !== null) {
        const newValueState = [...prevFields[field].value];
        newValueState[index] = newValue;
        const newEditingState = [...prevFields[field].isEditing];
        newEditingState[index] = false;
        return {
          ...prevFields,
          [field]: { value: newValueState, isEditing: newEditingState }
        };
      } else {
        return {
          ...prevFields,
          [field]: { ...prevFields[field], value: newValue, isEditing: false }
        };
      }
    });
  };

  const handleAddNewItem = (field) => {
    if (fields[field] && fields[field].value && fields[field].isEditing) {
      setFields((prevFields) => ({
        ...prevFields,
        [field]: {
          value: [...prevFields[field].value, ""],
          isEditing: [...prevFields[field].isEditing, true]
        }
      }));
    }
  };

  const handleAddNewObjectItem = (field) => {
    setFields((prevFields) => {
      const newObject = {};
      Object.keys(prevFields[field].value[0] || {}).forEach(key => {
        newObject[key] = key === 'primary' ? false : '';
      });

      return {
        ...prevFields,
        [field]: {
          value: [...prevFields[field].value, newObject],
          isEditing: [...(prevFields[field].isEditing || []), true]
        }
      };
    });
  };

  const handleChangePrimary = (field, index) => {
    setFields((prevFields) => {
      const newArr = prevFields[field].value.map((item, idx) => {
        if (idx === index) {
          return { ...item, primary: true };
        } else {
          return { ...item, primary: false };
        }
      });
      return {
        ...prevFields,
        [field]: { ...prevFields[field], value: newArr },
      };
    });
  };

  const handleAddNewMeta = () => {
    if (currentLead) {
      if (!currentLead.meta) currentLead.meta = [];
      currentLead.meta.push({ "edit_name": "edit_value", isSelf: true });
    }
    updateLeads(currentLead);
  };

  const renderField = (field, label) => {
    if (skipFields.includes(field)) return;
    // if (field === "id") return;
    let isEditable = true;
    if (LEADS_PROTECTIVE_FIELDS.includes(field)) isEditable = false;

    return (
      <_Builtin.Block
        className={_utils.cx(_styles, "datacollection_textcontent")}
        tag="div"
        key={field}
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "datacollection_textcontent_label")}
          tag="div"
        >
          <_Builtin.Block tag="div">{label}</_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "datacollection_textcontent_data")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "datacol_tag")}
            tag="div"
          >
            <_Builtin.Block tag="div">
              {fields[field] && fields[field].isEditing && isEditable ? (
                <input
                  className="data-col-edit-input"
                  type="text"
                  value={fields[field].value}
                  onChange={(e) =>
                    setFields((prevFields) => ({
                      ...prevFields,
                      [field]: { ...prevFields[field], value: e.target.value },
                    }))
                  }
                  onBlur={(e) => handleBlur(field, e.target.value)}
                  autoFocus
                />
              ) : (
                fields[field] ? Utilities.isMillisecondTimestamp(fields[field].value) ? moment(fields[field].value).format("MMMM Do YYYY, h:mm:ss") : fields[field].value : ""
              )}
            </_Builtin.Block>
            {isEditable && (
              <_Builtin.Block
                className={_utils.cx(_styles, "icon-edit")}
                tag="div"
                onClick={() => handleIconClick(field)}
              >
                <_Builtin.Image
                  width="auto"
                  height="auto"
                  loading="lazy"
                  alt=""
                  src="images/6696a8d47fc7ecaa008089c2_inbox_03.svg"
                />
              </_Builtin.Block>
            )}
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    );
  };

  const renderArrayField = (field, label) => {
    return (
      <_Builtin.Block
        className={_utils.cx(_styles, "datacollection_textcontent")}
        tag="div"
        key={field}
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "datacollection_textcontent_label")}
          tag="div"
        >
          <_Builtin.Block tag="div">{label}</_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "datacollection_textcontent_data")}
          tag="div"
        >
          {fields[field] && fields[field].value.map((item, index) => (
            <_Builtin.Block
              className={_utils.cx(_styles, "datacol_tag")}
              tag="div"
              key={index}
            >
              <_Builtin.Block tag="div">
                {fields[field] && fields[field].isEditing[index] ? (
                  <input
                    className="data-col-edit-input"
                    type="text"
                    value={item}
                    onChange={(e) =>
                      setFields((prevFields) => {
                        const newValueState = [...prevFields[field].value];
                        newValueState[index] = e.target.value;
                        return {
                          ...prevFields,
                          [field]: { ...prevFields[field], value: newValueState },
                        };
                      })
                    }
                    onBlur={(e) => handleBlur(field, e.target.value, index)}
                    autoFocus
                  />
                ) : (
                  item
                )}
              </_Builtin.Block>
              <_Builtin.Block
                className={_utils.cx(_styles, "icon-edit")}
                tag="div"
                onClick={() => handleIconClick(field, index)}
              >
                <_Builtin.Image
                  width="auto"
                  height="auto"
                  loading="lazy"
                  alt=""
                  src="images/6696a8d47fc7ecaa008089c2_inbox_03.svg"
                />
              </_Builtin.Block>
            </_Builtin.Block>
          ))}
          <_Builtin.Block
            className={_utils.cx(_styles, "datacol_addtag")}
            tag="div"
            onClick={() => handleAddNewItem(field)}
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "icon-add")}
              tag="div"
            >
              <_Builtin.Image
                width="auto"
                height="auto"
                loading="lazy"
                alt=""
                src="images/66969177fb74722e775dde42_inbox_01.svg"
              />
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    );
  };

  const renderObjectField = (field, label) => {
    const obj = fields[field]?.value || {};
    return (
      <_Builtin.Block
        className={_utils.cx(_styles, "datacollection_textcontent")}
        tag="div"
        key={field}
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "datacollection_textcontent_label")}
          tag="div"
        >
          <_Builtin.Block tag="div">{label}</_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "datacollection_textcontent_data")}
          tag="div"
        >
          {Object.entries(obj).map(([key, value]) => (
            <_Builtin.Block
              className={_utils.cx(_styles, "datacol_tag")}
              tag="div"
              key={key}
            >
              <_Builtin.Block tag="div">
                {key}:{" "}
                {fields[field] && fields[field].isEditing ? (
                  <input
                    className="data-col-edit-input"
                    type="text"
                    value={value}
                    onChange={(e) =>
                      setFields((prevFields) => ({
                        ...prevFields,
                        [field]: {
                          ...prevFields[field],
                          value: {
                            ...prevFields[field].value,
                            [key]: e.target.value,
                          },
                        },
                      }))
                    }
                    onBlur={(e) => handleBlur(field, { ...obj, [key]: e.target.value })}
                    autoFocus
                  />
                ) : (
                  value
                )}
              </_Builtin.Block>
              <_Builtin.Block
                className={_utils.cx(_styles, "icon-edit")}
                tag="div"
                onClick={() => handleIconClick(field)}
              >
                <_Builtin.Image
                  width="auto"
                  height="auto"
                  loading="lazy"
                  alt=""
                  src="images/6696a8d47fc7ecaa008089c2_inbox_03.svg"
                />
              </_Builtin.Block>
            </_Builtin.Block>
          ))}
        </_Builtin.Block>
      </_Builtin.Block>
    );
  };

  const handleEditMeta = (event, index, obj, key) => {
    const target = event.target.name;
    const value = event.target.value;
    let objCopy = Object.assign({}, obj);
    if (target === "key") {
      objCopy[value] = objCopy[key];
      delete objCopy[key];
    } else {
      objCopy[key] = value;
    }
    updates.meta[index] = objCopy;
  };

  const renderInput = (field, obj, index) => {
    switch (true) {
      case fields[field] && fields[field].isEditing && fields[field].isEditing[index]:
        return Object.entries(obj).map(([key, value], idx) => {
          if (key !== 'primary' && key !== 'isSelf') {
            switch (field) {
              case "meta":
                return (
                  <div>
                    <label htmlFor="key">Key:</label>
                    <input defaultValue={key} name="key" onChange={(e) => handleEditMeta(e, index, obj, key)} />
                    <label htmlFor="value">Value:</label>
                    <input defaultValue={value} name="value" onChange={(e) => handleEditMeta(e, index, obj, key)} />
                  </div>
                );
              default:
                return (
                  <input
                    key={key}
                    className="data-col-edit-input margin-right-5"
                    type="text"
                    value={value}
                    onChange={(e) =>
                      setFields((prevFields) => {
                        const newArr = [...prevFields[field].value];
                        newArr[index] = { ...newArr[index], [key]: key === "meta" ? e.target.value.split(':')[1] : e.target.value };
                        return {
                          ...prevFields,
                          [field]: { ...prevFields[field], value: newArr },
                        };
                      })
                    }
                    onBlur={() => handleBlur(field, fields[field].value)}
                  />
                );
            }

          }
          return null;
        });
      default:
        return Object.entries(obj).map(([key, value], idx) => {
          if (key !== 'primary') {
            switch (key) {
              case "number": {
                const number = Utilities.numberValidator(value);
                if (number.isValid)
                  return number.internationalFormat;
                return <span> {value}
                  <img className="width-10" src="/images/icon-alert.svg" alt="" title="Invalid Phone Number" />
                </span>;
              }
              case "isSelf":
                return null;
              default:
                const isSelf = obj.isSelf;
                return <span style={{ color: isSelf ? "#c5a944" : "inherit" }} key={key}>{key}:{value}</span>;
            }
          }
          return null;
        });
    }
  };

  const renderArrayOfObjects = (field, label) => {
    const arr = fields[field]?.value || [];
    let handleAddNewObjectItem_ = handleAddNewObjectItem;
    switch (field) {
      case "meta":
        handleAddNewObjectItem_ = handleAddNewMeta;
        break;
      default:
        break;
    }
    return (
      <_Builtin.Block
        className={_utils.cx(_styles, "datacollection_textcontent")}
        tag="div"
        key={field}
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "datacollection_textcontent_label")}
          tag="div"
        >
          <_Builtin.Block tag="div">{label}</_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "datacollection_textcontent_data")}
          tag="div"
        >
          {arr.map((obj, index) =>
          (
            <React.Fragment key={index}>
              <_Builtin.Block
                className={_utils.cx(_styles, "datacol_tag")}
                tag="div"
              >
                {obj.primary === true && <_Builtin.Block
                  className={_utils.cx(_styles, "icon-primary")}
                  tag="div"
                >
                  <_Builtin.Image
                    width="auto"
                    height="auto"
                    loading="lazy"
                    alt=""
                    src="/images/icon-star.svg"
                  />
                </_Builtin.Block>}
                <_Builtin.Block tag="div">
                  {renderInput(field, obj, index)}
                </_Builtin.Block>
                <_Builtin.Block
                  className={_utils.cx(_styles, "icon-edit")}
                  tag="div"
                  onClick={() => handleIconClick(field, index)}
                >
                  <_Builtin.Image
                    width="auto"
                    height="auto"
                    loading="lazy"
                    alt=""
                    src="images/6696a8d47fc7ecaa008089c2_inbox_03.svg"
                  />
                </_Builtin.Block>
              </_Builtin.Block>
              {!obj.primary && (
                <_Builtin.Block
                  className={_utils.cx(_styles, "datacol_addprimary")}
                  tag="div"
                  onClick={() => handleChangePrimary(field, index)}
                >
                  <_Builtin.Block
                    className={_utils.cx(_styles, "icon-star")}
                    tag="div"
                  >
                    <_Builtin.Image
                      width="auto"
                      height="auto"
                      loading="lazy"
                      alt=""
                      src="/images/icon-star.svg"
                    />
                  </_Builtin.Block>
                </_Builtin.Block>
              )}
            </React.Fragment>
          ))}
          <_Builtin.Block
            className={_utils.cx(_styles, "datacol_addtag")}
            tag="div"
            onClick={() => handleAddNewObjectItem_(field)}
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "icon-add")}
              tag="div"
            >
              <_Builtin.Image
                width="auto"
                height="auto"
                loading="lazy"
                alt=""
                src="images/66969177fb74722e775dde42_inbox_01.svg"
              />
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    );
  };

  function capitalizeFirstLetter(str) {
    if (typeof str !== 'string' || str.length === 0) {
      return str; // Return unchanged if input is not a string or empty
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <_Component className={_utils.cx(_styles, "datacolletion-body")} tag="div">
      {leadLoading ? <Loader /> : <>
        {Object.keys(fields).map((key) => {
          if (Array.isArray(fields[key].value)) {
            if (fields[key].value.length > 0 && typeof fields[key].value[0] === 'object') {
              return renderArrayOfObjects(key, capitalizeFirstLetter(key.replace(/([a-z])([A-Z])/g, '$1 $2')));
            } else {
              return renderArrayField(key, capitalizeFirstLetter(key.replace(/([a-z])([A-Z])/g, '$1 $2')));
            }
          } else if (typeof fields[key].value === 'object' && fields[key].value !== null) {
            return renderObjectField(key, capitalizeFirstLetter(key.replace(/([a-z])([A-Z])/g, '$1 $2')));
          } else {
            return renderField(key, capitalizeFirstLetter(key.replace(/([a-z])([A-Z])/g, '$1 $2')));
          }
        })
        }
        <_Builtin.Block
          className={_utils.cx(_styles, "datacollection_textcontent")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "datacollection_textcontent_label")}
            tag="div"
          >
            <_Builtin.Block tag="div">{"Notes"}</_Builtin.Block>
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "datacollection_textcontent_data")}
            tag="div"
          >
            <_Builtin.FormTextarea
              className={_utils.cx(_styles, "textarea")}
              required={false}
              autoFocus={false}
              maxLength={5000}
              name="field-9"
              data-name="Field 9"
              id="field-9"
              onChange={handleNotes}
              defaultValue={note}
            />
            <_Builtin.Link
              className={_utils.cx(_styles, "btn", "_w-100")}
              button={true}
              block=""
              options={{
                href: "#",
              }}
              onClick={handleSave}
            >
              {"Save"}
            </_Builtin.Link>
          </_Builtin.Block>
        </_Builtin.Block>
      </>}
    </_Component>
  );
}

"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./Bubbleinbound.module.css";
import moment from "moment";

export function Bubbleinbound({
  as: _Component = _Builtin.Block,
  bubbletext = "",
  time = "13m",
  handleClickInteraction = () => { },
  handleActiveTab = () => { },
  ...props
}) {

  const handleClick = () => {
    handleActiveTab("Tab 2");
    handleClickInteraction(props.id);
  };

  return (
    <_Component
      className={_utils.cx(_styles, "bubblecontainer_inbound-mainwrap")}
      tag="div"
    >
      <_Builtin.Block
        className={_utils.cx(_styles, "bubblecontainer_inbound-wrap")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "bubblecontainer_inbound")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "bubble_inbound")}
            tag="div"
            style={{ display: "flex" }}
          >
            <_Builtin.Block tag="div">{bubbletext}</_Builtin.Block>
            <_Builtin.Image
              width="auto"
              height="auto"
              loading="lazy"
              alt=""
              onClick={handleClick}
              src="/images/info-circle-black.svg"
              style={{
                cursor: "pointer",
                width: "15px",
                marginLeft: "5px",
                color: "black"
              }}
            />
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "messagestatus_div")}
            tag="div"
          >
            <_Builtin.Block tag="div">{moment(time).fromNow()}</_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}

"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { Datacollect } from "./Datacollect";
import * as _utils from "./utils";
import _styles from "./Inboxcol3.module.css";

export function Inboxcol3({ as: _Component = _Builtin.Block, dataCollectHandler = () => { }, currentLead = {},
leadLoading = false
}) {
  return (
    <_Component className={_utils.cx(_styles, "dashboard-col3")} tag="div">
      <_Builtin.Block
        className={_utils.cx(_styles, "card-datacollection")}
        tag="div"
      >
        <_Builtin.Block className={_utils.cx(_styles, "inbox-top")} tag="div">
          <_Builtin.Block
            className={_utils.cx(_styles, "phonetop_left")}
            tag="div"
          >
            <_Builtin.Heading
              className={_utils.cx(_styles, "phoneheader_text")}
              tag="h3"
            >
              {"Data Collection"}
            </_Builtin.Heading>
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "phonetop_right")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "phonetop_iconbtn")}
              tag="div"
            >
              <_Builtin.Image
                className={_utils.cx(_styles, "image-100")}
                width="auto"
                height="auto"
                loading="lazy"
                alt=""
                src="images/66968578d5889c78730e31aa_icon_threedots.svg"
              />
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
        <Datacollect dataCollectHandler={dataCollectHandler} currentLead={currentLead} 
          leadLoading={leadLoading}
        />
      </_Builtin.Block>
    </_Component>
  );
}

"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _interactions from "./interactions";
import * as _utils from "./utils";
import _styles from "./Controldiv.module.css";

const _interactionsData = JSON.parse(
  '{"events":{"e-967":{"id":"e-967","name":"","animationType":"preset","eventTypeId":"MOUSE_CLICK","action":{"id":"","actionTypeId":"GENERAL_START_ACTION","config":{"delay":0,"easing":"","duration":0,"actionListId":"a-18","affectedElements":{},"playInReverse":false,"autoStopEventId":"e-968"}},"mediaQueries":["main","medium","small","tiny"],"target":{"id":"bf5a655c-eae0-7f9d-c3d1-63cf7693f6f1","appliesTo":"ELEMENT","styleBlockIds":[]},"targets":[{"id":"bf5a655c-eae0-7f9d-c3d1-63cf7693f6f1","appliesTo":"ELEMENT","styleBlockIds":[]}],"config":{"loop":false,"playInReverse":false,"scrollOffsetValue":null,"scrollOffsetUnit":null,"delay":null,"direction":null,"effectIn":null},"createdOn":1721213398807}},"actionLists":{"a-18":{"id":"a-18","title":"import popup [show]","actionItemGroups":[{"actionItems":[{"id":"a-18-n","actionTypeId":"GENERAL_DISPLAY","config":{"delay":0,"easing":"","duration":0,"target":{"selector":".import-popup","selectorGuids":["41744e62-6efc-b5ef-8c38-cc2a49bc8b35"]},"value":"flex"}}]}],"useFirstGroupAsInitialState":false,"createdOn":1701788694968}},"site":{"mediaQueries":[{"key":"main","min":992,"max":10000},{"key":"medium","min":768,"max":991},{"key":"small","min":480,"max":767},{"key":"tiny","min":0,"max":479}]}}'
);

export function Controldiv({ as: _Component = _Builtin.Block }) {
  _interactions.useInteractions(_interactionsData, _styles);

  return (
    <_Component className={_utils.cx(_styles, "div-control")} tag="div">
      <_Builtin.Block
        className={_utils.cx(_styles, "phonetop_iconbtn", "mr-5")}
        tag="div"
      >
        <_Builtin.Image
          className={_utils.cx(_styles, "image-100")}
          width="auto"
          height="auto"
          loading="lazy"
          alt=""
          src="images/66969177ee1a6a8cbeae5e12_inbox_02.svg"
        />
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "phonetop_iconbtn", "mr-5")}
        tag="div"
      >
        <_Builtin.Image
          className={_utils.cx(_styles, "image-100")}
          width="auto"
          height="auto"
          loading="lazy"
          alt=""
          src="images/66968578d5889c78730e31aa_icon_threedots.svg"
        />
      </_Builtin.Block>
      <_Builtin.Link
        className={_utils.cx(_styles, "btn")}
        data-w-id="bf5a655c-eae0-7f9d-c3d1-63cf7693f6f1"
        button={false}
        block="inline"
        options={{
          href: "#",
        }}
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "copylink_icon")}
          tag="div"
        >
          <_Builtin.Image
            loading="lazy"
            width="auto"
            height="auto"
            alt=""
            src="images/66968578d5889c78730e31af_icon_download.svg"
          />
        </_Builtin.Block>
        <_Builtin.Block tag="div">{"Import File"}</_Builtin.Block>
      </_Builtin.Link>
    </_Component>
  );
}

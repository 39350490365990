import React, { useRef, useContext } from "react";
import Main from "../Main";
import { PageLogin } from "../../devlink/PageLogin";
import Utilities from "../../common/Utilities";
import { UserContext } from "../../contexts/UserContexts";
import { Loader } from "../common/Loader";

function Login() {
    const formRef = useRef(null);
    const { user, loading, login } = useContext(UserContext);

    const handleSubmit = async () => {
        const form = formRef.current;
        if (form) {
            const email = form.querySelector('input[name="email"]').value;
            const password = form.querySelector('input[name="password"]').value;

            const isValidEmail = Utilities.emailValidator(email);

            if (!isValidEmail) {
                alert("Invalid email address");
                return;
            }

            if (!email || !password) {
                alert("Please fill in all fields");
                return;
            }

            try {
                await login(email, password);
              } catch (error) {
                alert(error)
              }
        }
    };

    if (loading) {
        return <Loader />;
      }

    return (
        <Main>
            {!user && <PageLogin formRef={formRef} handleSubmit={handleSubmit} /> }
        </Main>
    );
}

export default Login;

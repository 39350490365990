"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./Bubbleoutbound.module.css";
import moment from "moment";

export function Bubbleoutbound({
  as: _Component = _Builtin.Block,
  bubbletext = "Hello Patrick, just to let you know we are still working through your support case. Engineering may need to cotnact you later for info",
  time = "Seen • 14m",
  handleClickInteraction = () => { },
  handleActiveTab = () => { },
  ...props
}) {

  const handleClick = () => {
    handleActiveTab("Tab 2");
    handleClickInteraction(props.id)
  }

  return (
    <_Component
      className={_utils.cx(_styles, "bubblecontainer_outbound")}
      tag="div"
    >
      <_Builtin.Block
        className={_utils.cx(_styles, "bubble_outbound")}
        tag="div"
        style={{ display: "flex" }}
      >
        <_Builtin.Block tag="div">{bubbletext}</_Builtin.Block>
        <_Builtin.Image
          width="auto"
          height="auto"
          loading="lazy"
          alt=""
          src="/images/info-circle.svg"
          onClick={handleClick}
          style={{
            cursor: "pointer",
            width: "15px",
            marginLeft: "5px"
          }}
        />
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "messagestatus_div")}
        tag="div"
      >
        <_Builtin.Block tag="div">{moment(time).fromNow()}</_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}

"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./PageLogin.module.css";

export function PageLogin({
  as: _Component = _Builtin.Block,
  emailfield = {},
  passwordfield = {},
  forgotpassword = {},
  btnSignin = {},
  formRef,
  handleSubmit
}) {
  return (
    <_Component className={_utils.cx(_styles, "login-wrapper")} tag="div">
      <_Builtin.Block
        className={_utils.cx(_styles, "card-login-container")}
        tag="div"
      >
        <_Builtin.Block className={_utils.cx(_styles, "branddiv")} tag="div">
          <_Builtin.Block
            className={_utils.cx(_styles, "brand", "_w-100")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "logo", "_w-50")}
              tag="div"
            >
              <_Builtin.Image
                width="auto"
                height="auto"
                loading="lazy"
                alt=""
                src="images/669a195cb6606c529cb79933_logo-icon.svg"
              />
            </_Builtin.Block>
            <_Builtin.Block
              className={_utils.cx(_styles, "app-name", "align-center")}
              tag="div"
            >
              <_Builtin.Block
                className={_utils.cx(_styles, "logo-name", "_w-100")}
                tag="div"
              >
                <_Builtin.Image
                  width="auto"
                  height="auto"
                  loading="lazy"
                  alt=""
                  src="images/669a195c26fdbed985bd66da_logo-text.svg"
                />
              </_Builtin.Block>
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block className={_utils.cx(_styles, "card-login")} tag="div">
          <_Builtin.FormWrapper
            className={_utils.cx(_styles, "login-formblock")}
          >
            <_Builtin.FormForm
              name="email-form"
              data-name="Email Form"
              method="get"
              id="email-form"
              ref={formRef}
            >
              <_Builtin.Block
                className={_utils.cx(_styles, "log-in-header")}
                tag="div"
              >
                <_Builtin.Heading
                  className={_utils.cx(_styles, "login-hd")}
                  tag="h3"
                >
                  {"Login"}
                </_Builtin.Heading>
                <_Builtin.Paragraph
                  className={_utils.cx(_styles, "login-in-p-style1")}
                >
                  {"Enter your credential to access your account"}
                </_Builtin.Paragraph>
              </_Builtin.Block>
              <_Builtin.Block
                className={_utils.cx(_styles, "form-row")}
                tag="div"
              >
                <_Builtin.FormBlockLabel
                  className={_utils.cx(_styles, "login-field-label")}
                >
                  {"Email"}
                </_Builtin.FormBlockLabel>
                <_Builtin.Block
                  className={_utils.cx(_styles, "form-control")}
                  tag="div"
                >
                  <_Builtin.FormTextInput
                    className={_utils.cx(_styles, "login-textfield")}
                    name="email"
                    maxLength={256}
                    data-name="Name 2"
                    placeholder="Email Address"
                    disabled={false}
                    type="text"
                    required={false}
                    autoFocus={false}
                    id="name-2"
                    {...emailfield}
                  />
                </_Builtin.Block>
              </_Builtin.Block>
              <_Builtin.Block
                className={_utils.cx(_styles, "form-row")}
                tag="div"
              >
                <_Builtin.FormBlockLabel
                  className={_utils.cx(_styles, "login-field-label")}
                >
                  {"Password"}
                </_Builtin.FormBlockLabel>
                <_Builtin.Block
                  className={_utils.cx(_styles, "form-control")}
                  tag="div"
                >
                  <_Builtin.FormTextInput
                    className={_utils.cx(_styles, "login-textfield")}
                    name="password"
                    maxLength={256}
                    data-name="Name 2"
                    placeholder="Password"
                    disabled={false}
                    type="password"
                    required={false}
                    autoFocus={false}
                    id="name-2"
                    {...passwordfield}
                  />
                </_Builtin.Block>
              </_Builtin.Block>
              <_Builtin.Block
                className={_utils.cx(_styles, "forgotpassword-container")}
                tag="div"
              >
                <_Builtin.Link
                  className={_utils.cx(_styles, "login-link")}
                  button={false}
                  block=""
                  options={{
                    href: "#",
                  }}
                  {...forgotpassword}
                >
                  {"Forgot Password?"}
                </_Builtin.Link>
              </_Builtin.Block>
              <_Builtin.Block
                className={_utils.cx(_styles, "btn-container")}
                tag="div"
              >
                <_Builtin.Link
                  className={_utils.cx(_styles, "login-btn", "_w-100")}
                  button={true}
                  block=""
                  options={{
                    href: "#",
                  }}
                  onClick={handleSubmit}
                  {...btnSignin}
                >
                  {"Sign In"}
                </_Builtin.Link>
              </_Builtin.Block>
            </_Builtin.FormForm>
            <_Builtin.FormSuccessMessage>
              <_Builtin.Block tag="div">
                {"Thank you! Your submission has been received!"}
              </_Builtin.Block>
            </_Builtin.FormSuccessMessage>
            <_Builtin.FormErrorMessage>
              <_Builtin.Block tag="div">
                {"Oops! Something went wrong while submitting the form."}
              </_Builtin.Block>
            </_Builtin.FormErrorMessage>
          </_Builtin.FormWrapper>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "bottom-container")}
          tag="div"
        >
          <_Builtin.Paragraph
            className={_utils.cx(_styles, "login-in-p-style1")}
          >
            {"Don't have an account yet? "}
            <_Builtin.Link
              className={_utils.cx(_styles, "span-link-style1")}
              button={false}
              block=""
              options={{
                href: "/signup",
              }}
            >
              {"Sign up"}
            </_Builtin.Link>
          </_Builtin.Paragraph>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}

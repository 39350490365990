"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./StatSignup.module.css";

export function StatSignup({ as: _Component = _Builtin.Block }) {
  return (
    <_Component className={_utils.cx(_styles, "status_tag")} tag="div">
      <_Builtin.HtmlEmbed
        className={_utils.cx(_styles, "svg-icon-embed", "small")}
        value="%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22UTF-8%22%3F%3E%0A%3Csvg%20id%3D%22Layer_1%22%20data-name%3D%22Layer%201%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20100%20100%22%3E%0A%20%20%3Cdefs%3E%0A%20%20%20%20%3Cstyle%3E%0A%20%20%20%20%20%20.cls-1%20%7B%0A%20%20%20%20%20%20%20%20fill%3A%20CurrentColor%3B%0A%20%20%20%20%20%20%20%20stroke-width%3A%200px%3B%0A%20%20%20%20%20%20%7D%0A%20%20%20%20%3C%2Fstyle%3E%0A%20%20%3C%2Fdefs%3E%0A%20%20%3Cpath%20class%3D%22cls-1%22%20d%3D%22M37.34%2C65.57c.53-.68.88-1.25%2C1.34-1.71%2C14.65-14.67%2C29.32-29.31%2C43.96-43.99%2C2.22-2.23%2C4.7-3.42%2C7.87-2.5%2C4.94%2C1.45%2C6.85%2C7.29%2C3.7%2C11.35-.47.61-1.03%2C1.16-1.58%2C1.7-16.42%2C16.43-32.85%2C32.86-49.28%2C49.29-4.31%2C4.31-8.1%2C4.3-12.46-.05-7.96-7.96-15.91-15.93-23.89-23.88-1.48-1.48-2.62-3.11-2.74-5.25-.17-3%2C1.05-5.38%2C3.67-6.84%2C2.68-1.5%2C5.38-1.31%2C7.88.51.72.52%2C1.35%2C1.17%2C1.98%2C1.8%2C6.4%2C6.4%2C12.79%2C12.8%2C19.55%2C19.56Z%22%2F%3E%0A%3C%2Fsvg%3E"
      />
      <_Builtin.Block tag="div">{"Signed up"}</_Builtin.Block>
    </_Component>
  );
}

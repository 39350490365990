import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import { PageHome } from "./devlink/PageHome";
import { UserContext } from "./contexts/UserContexts";
import Main from "./component/Main";
import WSS from "./services/websocket";
import ClientWatcher from "./ClientWatcher";
import CallManager, { USER_STATUS } from "./call/CallManager";
import { ReactiveQuery } from "./QueryProvider";
import { Dialer } from "./component/Dialer";
import { Modal } from "./component/Modal";

// SAMPLE DATA
import { interactions, leadsData, sampleImportData, leadskey } from "./common/dataSample";

const wsServer = process.env.REACT_APP_WSS || "ws://localhost:5000";
const wss = new WSS(wsServer);
const client = new ClientWatcher(wss);
const callManager = new CallManager(wss);
let inboxes = [];
let interactionsData = [];
let transcriptionData = [];
let baseInfo = {};
let leadBaseInfo = {};
let historyData = [];
let interactionDataLeads = [];
let transcriptsLeadData = [];

function App() {
    const { logout, user } = useContext(UserContext);

    const [inboxId, setInboxId] = useState(null);
    const [inboxIdLead, setInboxIdLead] = useState(null);
    const [interactionId, setInteractionId] = useState(null);
    const [leadInteractionId, setLeadInteractionId] = useState(null);
    const [callStatus, setCallStatus] = useState("default");
    const [leadId, setLeadId] = useState(null);
    const [agentStatus, setAgentStatus] = useState(USER_STATUS.OFFLINE);
    const [callErrorMsg, setCallErrMsg] = useState("");
    const [inputDevices, setInputDevices] = useState([]);
    const [outputDevices, setOutputDevices] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModalKeypad = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    let isFetchingInteractions = false;
    let isFetchingTanscriptions = false;
    let isFetchingInterLeads = false;
    let isFetchingTranscriptionLeads = false;
    let historyLoading = false;
    let currentLead = {};

    const { data: inboxData } = new ReactiveQuery(wss.subscribe.bind(wss), ["inboxes"]).find(client.fetchInbox.bind(client));
    const { data: interData, isLoading: interLoading, refetch: refetchInter, hasNextPage, fetchNextPage } = new ReactiveQuery(wss.subscribe.bind(wss), ["interactions"])
        .findInfinite(({ last }) => client.fetchInteraction({ last, inboxId }), !!inboxId, {}, true);
    const { data: transData, isLoading: transLoading, refetch } = new ReactiveQuery(wss.subscribe.bind(wss), ["transcripts"]).find(() => client.fetchTranscription(interactionId), !!interactionId);
    const {
        data: leadAllData,
        isLoading: leadTableLoading,
        refetch: refetchLeadAll,
        fetchNextPage: fetchNextPageLeads
    } = new ReactiveQuery(wss.subscribe.bind(wss), ["alllead"]).findInfinite(({ last }) => client.fetchAllLead({ last }), !!user);
    const { data: leadHistoryData, isLoading: loadingHistory, refetch: refetchLeadHistory } = new ReactiveQuery(wss.subscribe.bind(wss), ["leadHistory"]).find(() => client.fetchLeadHistory(leadId), !!leadId);
    const { data: interDataLeads, isLoading: interLeadsLoading, refetch: refetchInterLead, hasNextPage: interLeadsHasNextPage, fetchNextPage: interLeadsFetchNextPage } = new ReactiveQuery(wss.subscribe.bind(wss), ["interleads"])
        .findInfinite(({ last }) => client.fetchInteraction({ last, inboxId: inboxIdLead }), !!inboxIdLead, {}, true);
    const { data: transLeadData, isLoading: transLeadLoading, refetch: refetchTransLead } = new ReactiveQuery(wss.subscribe.bind(wss), ["transcriptslead"]).find(() => client.fetchTranscription(leadInteractionId), !!leadInteractionId);

    inboxes = inboxData;
    interactionsData = interData;
    isFetchingInteractions = interLoading && inboxId;
    isFetchingTanscriptions = transLoading;
    transcriptionData = transData ? transData.transcript : [];
    historyData = leadHistoryData;
    historyLoading = loadingHistory;

    interactionDataLeads = interDataLeads;
    isFetchingInterLeads = inboxIdLead && interLeadsLoading;

    isFetchingTranscriptionLeads = leadInteractionId && transLeadLoading;
    transcriptsLeadData = transLeadData;

    const selectedInbox = inboxes && inboxes.find((item) => item.id === inboxId);
    const { data: leadData, isLoading: leadLoading, refetch: refetchLead } = new ReactiveQuery(wss.subscribe.bind(wss), ["lead"]).find(() => client.fetchLead(selectedInbox?.leadId), !!selectedInbox && !isFetchingInteractions);
    currentLead = leadData;
    baseInfo = selectedInbox?.baseInfo;

    const selectedLeadInbox = inboxes && inboxes.find((item) => item.id === inboxIdLead);
    leadBaseInfo = selectedLeadInbox?.baseInfo;

    useEffect(() => { inboxId && refetchInter(); }, [inboxId, refetchInter]);
    useEffect(() => { interactionId && refetch(); }, [interactionId, refetch]);
    useEffect(() => { selectedInbox && refetchLead(); }, [selectedInbox, inboxId, refetchLead]);
    useEffect(() => { refetchLeadAll(); }, [refetchLeadAll]);
    useEffect(() => { leadId && refetchLeadHistory(); }, [leadId, refetchLeadHistory]);
    useEffect(() => { inboxIdLead && refetchInterLead(); }, [inboxIdLead, refetchInterLead]);
    useEffect(() => { leadInteractionId && refetchTransLead(); }, [leadInteractionId, refetchTransLead]);

    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [dialerLoading, setDialerLoading] = useState(false);
    const [isLeadsLoadingMore, setIsLeadsLoadingMore] = useState(false);

    useEffect(() => {
        callManager.on("ready", (loading) => {
            setDialerLoading(loading);
        });
        callManager.on("status", ({ status, err }) => {
            setCallStatus(status);
            setCallErrMsg(err);
        });
        callManager.on("agentStatus", (status) => {
            setAgentStatus(status);
        });
        callManager.on("audioDevices", ({ input, output }) => {
            setInputDevices(input);
            setOutputDevices(output);
        });
    }, []);
    /**START DIALER  */
    useEffect(() => {
        if (user) {
            callManager.setUserId(user._id);
        }
    }, [user]);


    const handleAcceptCall = () => {
        callManager.accept();
        setInboxId(callManager.Info.inboxId);
        const idx = inboxes.findIndex((item) => item.id === callManager.Info.inboxId);
        if (idx > -1) {
            //move element to first
            const item = inboxes.splice(idx, 1);
            inboxes.unshift(item[0]);
        }
    };

    const dialerHandler = async (number) => {
        callManager.makeCall(number);
    };

    const handleHangUp = () => {
        setDialerLoading(true);
        callManager.reject();
    };
    /** END DIALER  */

    // Function to handle data collection
    const dataCollectHandler = (data) => {
        if (data && data.id) client.updateData(data);
    };

    // Function to handle sending message in CONVERSATION TAB
    const handleSendingMessage = (message) => {
        // const newMessage = {
        //     id: 1,
        //     leadId: 1,
        //     inboxId: 1,
        //     type: "",
        //     channelId: "",
        //     content: message,
        //     direction: "outbound",
        //     createdAt: moment().valueOf(),
        //     updatedAt: moment().valueOf()
        // };
        // setInteractionsData((prevData) => [...prevData, newMessage]);
    };

    // Function to handle sending message in TRANSCRIPTION TAB
    const handleTranscriptMessage = (message) => {
        const newMessage = {
            direction: "outbound",
            message,
            timestamp: moment().valueOf()
        };
        // setTranscriptionData((prevData) => [...prevData, newMessage]);
    };

    const handleClickInteraction = (id) => {
        setInteractionId(id);
    };

    const handleClickInterLeads = (id) => {
        setLeadInteractionId(id);
    };

    const handleClickUserInbox = (data) => {
        if (inboxId === data.id) refetchInter();
        setInboxId(data.id);

        // const intData = interactions.filter((item) => item.inboxId === data.id);
        // const leadInfo = leadsData.find((item) => item.id === data.leadId);

        // Reset unread count
        const unread = data.channelInfo.unreadCount !== 0;
        if (unread) {
            client.resetUnreadCount(data);
        }
    };

    const resetConvo = () => {
        // setInteractionsData([]);
        // setTranscriptionData([]);
        // setCurrentLead({});
        // setBaseInfo({});
    };

    const loadMore = () => {
        if (hasNextPage) {
            setIsLoadingMore(true);
            fetchNextPage().finally(() => {
                setIsLoadingMore(false);
            });
        }
    };

    const loadMoreInter = () => {
        if (interLeadsHasNextPage) {
            setIsLoadingMore(true);
            interLeadsFetchNextPage().finally(() => {
                setIsLoadingMore(false);
            });
        }
    };

    const loadMoreLeads = () => {
        setIsLeadsLoadingMore(true);
        fetchNextPageLeads().finally(() => {
            setTimeout(() => {
                setIsLeadsLoadingMore(false);
            }, 1000);
        });
    };

    const handlerRowClick = (lead) => {
        if (inboxIdLead) setInboxIdLead(null);
        setLeadId(lead.id);
    };

    const handleHistoryClick = (data) => {
        if (data.inboxId === inboxIdLead) refetchInterLead();
        setInboxIdLead(data.inboxId);
    };

    const handleLogout = () => {
        logout();
    };

    return (
        <Main>
            <PageHome
                dialerHandler={dialerHandler}
                interactionsData={interactionsData}
                dataCollectHandler={dataCollectHandler}
                handleSendingMessage={handleSendingMessage}
                transcriptionData={transcriptionData}
                handleTranscriptMessage={handleTranscriptMessage}
                handleClickInteraction={handleClickInteraction}
                callStatus={callStatus}
                incomingCallInfo={callManager.Info}
                handleHangUp={handleHangUp}
                handleAcceptCall={handleAcceptCall}
                inboxes={inboxes}
                handleClickUserInbox={handleClickUserInbox}
                logout={handleLogout}
                currentLead={currentLead}
                baseInfo={baseInfo}
                loadMore={loadMore}
                leadsData={leadAllData}
                sampleImportData={sampleImportData}
                history={historyData}
                handlerRowClick={handlerRowClick}
                handleHistoryClick={handleHistoryClick}
                interDataLeads={inboxIdLead ? interactionDataLeads : []}
                transLeadData={transcriptsLeadData}
                leadBaseInfo={inboxIdLead ? leadBaseInfo : {}}
                handleClickInterLeads={handleClickInterLeads}
                loadMoreLeads={loadMoreLeads}
                // DATA FETCHING FOR LOADER
                isFetchingInteractions={isFetchingInteractions}
                isFetchingTanscriptions={isFetchingTanscriptions}
                isLoadingMore={isLoadingMore}
                dialerLoading={dialerLoading}
                leadLoading={leadLoading}
                leadTableLoading={leadTableLoading}
                historyLoading={historyLoading}
                interLeadsLoading={isFetchingInterLeads}
                transLeadsLoading={isFetchingTranscriptionLeads}
                isLeadsLoadingMore={isLeadsLoadingMore}
                leadskey={leadskey}
                loadMoreInter={loadMoreInter}
                inputDevices={inputDevices}
                outputDevices={outputDevices}
                onDeviceSelect={callManager.updateOutputDevice.bind(callManager)}
                onStatusChange={callManager.onAgentStatusChange.bind(callManager)}
                agentStatus={agentStatus}
                callErrorMsg={callErrorMsg}
                openModalKeypad={openModalKeypad}
                interactionId={interactionId}
            />
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <Dialer sendDigits={callManager.sendDigits.bind(callManager)} />
            </Modal>
        </Main>
    );
};

export default App;

"use client";
import React, { useEffect, useRef } from "react";
import * as _Builtin from "./_Builtin";
import { Transcriptionoutbound } from "./Transcriptionoutbound";
import { Transcriptioninbound } from "./Transcriptioninbound";
import * as _utils from "./utils";
import _styles from "./Leadtranscription.module.css";
import { Loader } from "../component/common/Loader";

export function Leadtranscription({ as: _Component = _Builtin.Block, data = [], transLeadsLoading = false }) {
  const chatContainerRef = useRef(null);
  const scrollToBottom = () => {
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [data]);


  return (
    <_Component
      className={_utils.cx(_styles, "conversation-wrap", "h-52vh")}
      tag="div"
    >
      <_Builtin.Block
        className={_utils.cx(_styles, "conversationwrap", "line-bottom")}
        tag="div"
      >
        {transLeadsLoading ? <Loader /> : <_Builtin.Block
          className={_utils.cx(_styles, "conversation")}
          tag="div"
          ref={chatContainerRef}
        >
          {data.map((item, index) => {
              if (item.direction === "outbound") {
                return (
                  <Transcriptionoutbound
                    key={index}
                    transcripttext={item.message}
                    timestamp={item.timestamp}
                  />
                );
              } else {
                return (
                  <Transcriptioninbound
                    key={index}
                    transcripttext={item.message}
                    timestamp={item.timestamp}
                  />
                );
              }
            })}
        </_Builtin.Block>}
      </_Builtin.Block>
      <_Builtin.Block className={_utils.cx(_styles, "smsreply")} tag="div">
        <_Builtin.Block
          className={_utils.cx(_styles, "smsreply_left")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "smsreply_file", "mb-2")}
            tag="div"
          >
            <_Builtin.Image
              className={_utils.cx(_styles, "image-100")}
              width="auto"
              height="auto"
              loading="lazy"
              alt=""
              src="images/66968578d5889c78730e31da_sms_01.svg"
            />
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "smsreply_file")}
            tag="div"
          >
            <_Builtin.Image
              className={_utils.cx(_styles, "image-100")}
              width="auto"
              height="auto"
              loading="lazy"
              alt=""
              src="images/66968578d5889c78730e31dd_sms_02.svg"
            />
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "sms_textfield-container")}
          tag="div"
        >
          <_Builtin.FormTextarea
            className={_utils.cx(_styles, "sms_textarea")}
            name="field-3"
            maxLength={5000}
            data-name="Field 3"
            placeholder="Type your message"
            required={false}
            autoFocus={false}
            id="field-3"
          />
          <_Builtin.Block
            className={_utils.cx(_styles, "sms_senddiv")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "sms-send")}
              tag="div"
            >
              <_Builtin.Image
                className={_utils.cx(_styles, "image-100")}
                width="auto"
                height="auto"
                loading="lazy"
                alt=""
                src="images/66968578d5889c78730e31e5_sms_03.svg"
              />
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}

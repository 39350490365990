export const STATUS = {
    DEFAULT: "default",
    INCOMING: "incoming",
    ONCALL: "oncall",
    ERROR: "error"
};

export const LEAD_STATUS = {
    SIGNED_UP: "signed_up",
    PROSPECT: "prospect",
    WAITING: "waiting",
};

export const customCalendar = {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'DD/MM/YYYY'
};

export const LEADS_PROTECTIVE_FIELDS = [
    "updatedAt",
    "createdAt",
];
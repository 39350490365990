"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _interactions from "./interactions";
import * as _utils from "./utils";
import _styles from "./Historyitem.module.css";

const _interactionsData = JSON.parse(
  '{"events":{"e-971":{"id":"e-971","name":"","animationType":"custom","eventTypeId":"MOUSE_CLICK","action":{"id":"","actionTypeId":"GENERAL_START_ACTION","config":{"delay":0,"easing":"","duration":0,"actionListId":"a-60","affectedElements":{},"playInReverse":false,"autoStopEventId":"e-972"}},"mediaQueries":["main","medium","small","tiny"],"target":{"id":"82b60712-1db2-82f6-9779-f7d827476abe","appliesTo":"ELEMENT","styleBlockIds":[]},"targets":[{"id":"82b60712-1db2-82f6-9779-f7d827476abe","appliesTo":"ELEMENT","styleBlockIds":[]}],"config":{"loop":false,"playInReverse":false,"scrollOffsetValue":null,"scrollOffsetUnit":null,"delay":null,"direction":null,"effectIn":null},"createdOn":1721223253184}},"actionLists":{"a-60":{"id":"a-60","title":"leadconversation [go]","actionItemGroups":[{"actionItems":[{"id":"a-60-n","actionTypeId":"GENERAL_DISPLAY","config":{"delay":0,"easing":"","duration":0,"target":{"selector":".leadconversation-main","selectorGuids":["90bbc54c-84e7-6f65-1011-735715452387"]},"value":"flex"}},{"id":"a-60-n-2","actionTypeId":"GENERAL_DISPLAY","config":{"delay":0,"easing":"","duration":0,"target":{"useEventTarget":"PARENT","selector":".history","selectorGuids":["69e20447-9e8a-4c7a-b9bf-d8787134d1b8"]},"value":"none"}}]}],"useFirstGroupAsInitialState":false,"createdOn":1721223255293}},"site":{"mediaQueries":[{"key":"main","min":992,"max":10000},{"key":"medium","min":768,"max":991},{"key":"small","min":480,"max":767},{"key":"tiny","min":0,"max":479}]}}'
);

export function Historyitem({
  as: _Component = _Builtin.Block,
  date = "Today",
  time = "2:17PM",
  icon = "images/66968578d5889c78730e31ee_icon_call_white.svg",
  activityType = "Inbound Call",
  activityDescription = "Phone Call from",
  activityUser = "Patrick Jenkins",
  historyData = "",
  handleHistoryClick = () => { }
}) {
  _interactions.useInteractions(_interactionsData, _styles);

  return (
    <_Component className={_utils.cx(_styles, "history_item")} tag="div">
      <_Builtin.Block className={_utils.cx(_styles, "history-date")} tag="div">
        <_Builtin.Block tag="div">{date}</_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block className={_utils.cx(_styles, "history-time")} tag="div">
        <_Builtin.Block tag="div">{time}</_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "history_icon_container")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "history_icon")}
          tag="div"
        >
          <_Builtin.Image
            width="auto"
            height="auto"
            loading="lazy"
            alt=""
            src={icon}
          />
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "history_line")}
          tag="div"
        />
      </_Builtin.Block>
      <_Builtin.Block className={_utils.cx(_styles, "history_right")} tag="div">
        <_Builtin.Block
          className={_utils.cx(_styles, "timeline_card")}
          // data-w-id="82b60712-1db2-82f6-9779-f7d827476abe"
          onClick={() => handleHistoryClick(historyData)}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "phoneinbox_item_left")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "phoneinbox_header")}
              tag="div"
            >
              {activityType}
            </_Builtin.Block>
            <_Builtin.Block
              className={_utils.cx(_styles, "phoneinbox_textcontent")}
              tag="div"
            >
              <_Builtin.Block
                className={_utils.cx(_styles, "phoneinbox_textcontent_label")}
                tag="div"
              >
                <_Builtin.Block tag="div">{activityDescription}</_Builtin.Block>
              </_Builtin.Block>
              <_Builtin.Block
                className={_utils.cx(_styles, "phoneinbox_textcontent_data")}
                tag="div"
              >
                <_Builtin.Block tag="div">{activityUser}</_Builtin.Block>
              </_Builtin.Block>
            </_Builtin.Block>
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "phoneinbox_item_right")}
            tag="div"
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "phoneinbox_item_option")}
              tag="div"
            >
              <_Builtin.Image
                width="auto"
                height="auto"
                loading="lazy"
                alt=""
                src="images/66968578d5889c78730e31aa_icon_threedots.svg"
              />
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}

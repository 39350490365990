import React, { createContext, useState, useEffect } from 'react';
import WebSocketClient from '../services/websocket';

const webSocketServer = process.env.REACT_APP_WSS || "ws://localhost:3000"

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const webSocket = new WebSocketClient(webSocketServer);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            fetchUser(token);
        } else {
            setLoading(false);
        }
    }, []);

    const fetchUser = async (token) => {
        try {
            if(webSocket){
                const response = await webSocket.callFunc("fetchUser", token)
                setUser(response);
            }
        } catch (error) {
            console.error('Failed to fetch user', error)
            localStorage.removeItem('token');
        }
        setLoading(false);
    };

    const login = async (usernameOrEmail, password) => {
        const response = await webSocket.callFunc("loginUser", {usernameOrEmail, password})
        localStorage.setItem('token', response.token);
        setUser(response.userId);
    };

    const logout = async () => {
        const token = localStorage.getItem('token');
        if (token) {
            await webSocket.callFunc("logoutUser", token)
        }
        localStorage.removeItem('token');
        setUser(null);
    };

    const register = async (username, email, password) => {
        const res = await webSocket.callFunc("registerUser", {username, email, password})
        localStorage.setItem('token', res.token)
        setUser(res.userId)
    };

    return (
        <UserContext.Provider value={{ user, loading, login, logout, register }}>
            {children}
        </UserContext.Provider>
    );
};
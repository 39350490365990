"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./Taglead.module.css";

export function Taglead({
  as: _Component = _Builtin.Block,
  tagtext = "Leads",
}) {
  return (
    <_Component className={_utils.cx(_styles, "lead_tag")} tag="div">
      <_Builtin.Block tag="div">{tagtext}</_Builtin.Block>
    </_Component>
  );
}

"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _interactions from "./interactions";
import * as _utils from "./utils";
import _styles from "./Sidemenu.module.css";

const _interactionsData = JSON.parse(
  '{"events":{"e-981":{"id":"e-981","name":"","animationType":"custom","eventTypeId":"MOUSE_CLICK","action":{"id":"","actionTypeId":"GENERAL_START_ACTION","config":{"delay":0,"easing":"","duration":0,"actionListId":"a-64","affectedElements":{},"playInReverse":false,"autoStopEventId":"e-982"}},"mediaQueries":["main","medium","small","tiny"],"target":{"appliesTo":"ELEMENT","styleBlockIds":[],"id":"1b401737-38a5-0d94-9e00-504faedee7a5"},"targets":[],"config":{"loop":false,"playInReverse":false,"scrollOffsetValue":null,"scrollOffsetUnit":null,"delay":null,"direction":null,"effectIn":null},"createdOn":1721374358023}},"actionLists":{"a-64":{"id":"a-64","title":"incoming call [show]","actionItemGroups":[{"actionItems":[{"id":"a-64-n","actionTypeId":"GENERAL_DISPLAY","config":{"delay":0,"easing":"","duration":0,"value":"flex","target":{"selector":".incoming-call","selectorGuids":["86ff3756-9d39-8af7-66dc-8e11123ab41d"]}}}]}],"createdOn":1721374360582,"useFirstGroupAsInitialState":false}},"site":{"mediaQueries":[{"key":"main","min":992,"max":10000},{"key":"medium","min":768,"max":991},{"key":"small","min":480,"max":767},{"key":"tiny","min":0,"max":479}]}}'
);

export function Sidemenu({ as: _Component = _Builtin.Block, logout = () => { } }) {
  _interactions.useInteractions(_interactionsData, _styles);

  return (
    <_Component className={_utils.cx(_styles, "sidemenu_container")} tag="div">
      <_Builtin.Block className={_utils.cx(_styles, "sidemenu-top")} tag="div">
        <_Builtin.Link
          className={_utils.cx(_styles, "link-block")}
          button={false}
          block="inline"
          options={{
            href: "#",
          }}
        >
          <_Builtin.Image
            loading="lazy"
            width="auto"
            height="auto"
            alt=""
            src="images/6696894d463a39e4fe6572f4_crm-logo.svg"
          />
        </_Builtin.Link>
        <_Builtin.Block
          className={_utils.cx(_styles, "sidemenu_list")}
          tag="div"
        >
          <_Builtin.Link
            className={_utils.cx(_styles, "sidemenu_link")}
            button={false}
            block="inline"
            options={{
              href: "#",
            }}
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "sidemenu_link_icon")}
              tag="div"
            >
              <_Builtin.Image
                loading="lazy"
                width="auto"
                height="auto"
                alt=""
                src="images/6696894c26c83d362f3678c0_crm_side-icon_01.svg"
              />
            </_Builtin.Block>
          </_Builtin.Link>
          <_Builtin.Link
            className={_utils.cx(_styles, "sidemenu_link")}
            button={false}
            block="inline"
            options={{
              href: "#",
            }}
          >
            <_Builtin.Block
              className={_utils.cx(_styles, "sidemenu_link_icon")}
              tag="div"
            >
              <_Builtin.Image
                loading="lazy"
                width="auto"
                height="auto"
                alt=""
                src="images/6696894cb9b1fda903fac334_crm_side-icon_02.svg"
              />
            </_Builtin.Block>
          </_Builtin.Link>
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block className={_utils.cx(_styles, "sidemenu-bot")} tag="div">
        {/* <_Builtin.Link
          className={_utils.cx(_styles, "sidemenu_link")}
          data-w-id="1b401737-38a5-0d94-9e00-504faedee7a5"
          button={false}
          block="inline"
          options={{
            href: "#",
          }}
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "sidemenu_link_icon")}
            tag="div"
          >
            <_Builtin.Image
              loading="lazy"
              width="auto"
              height="auto"
              alt=""
              src="images/669a160ba69be50c3a6ab696_side_02.svg"
            />
          </_Builtin.Block>
        </_Builtin.Link> */}
        <_Builtin.Link
          className={_utils.cx(_styles, "sidemenu_link", "logout")}
          button={false}
          block="inline"
          options={{
            href: "#",
          }}
          onClick={logout}
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "sidemenu_link_icon")}
            tag="div"
          >
            <_Builtin.Image
              loading="lazy"
              width="auto"
              height="auto"
              alt=""
              src="images/669a1631e508ef21c194bb1e_side_01.svg"
            />
          </_Builtin.Block>
        </_Builtin.Link>
      </_Builtin.Block>
    </_Component>
  );
}

import React, { createContext, useState, useContext } from 'react';

const ButtonContext = createContext();

export function ButtonProvider({ children }) {
    const [clickCount, setClickCount] = useState(0);

    const incrementClickCount = () => {
        setClickCount(prev => prev + 1);
    };

    return (
        <ButtonContext.Provider value={{ clickCount, incrementClickCount }}>
            {children}
        </ButtonContext.Provider>
    );
}

export function useButtonContext() {
    const context = useContext(ButtonContext);
    if (context === undefined) {
        throw new Error('useButtonContext must be used within a ButtonProvider');
    }
    return context;
}
import React, { createContext, useState, useEffect, useCallback } from 'react';
import UploadManager from '../UploadManager';
import WSS from '../services/websocket';
import { leadskey } from "../common/dataSample";

const webSocketServer = process.env.REACT_APP_WSS || "ws://localhost:3000";
const wss = new WSS(webSocketServer);
const uploadManager = new UploadManager(wss);

export const UploadContext = createContext();

export const UploadProvider = ({ children }) => {
    const [isLoading, setLoading] = useState();
    const [leadKeys, setLeadskey] = useState(leadskey);
    const [isDragging, setIsDragging] = useState(false);
    const [data, setData] = useState({});
    const [leadsDataValidation, setLeadDataValidation] = useState([]);
    const [selectedRows, setSelectedRows] = useState({});
    const [step, setStep] = useState(1);
    const [totalLeads, setTotalLeads] = useState({});
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        uploadManager.listen();
    }, []);

    useEffect(() => {
        setLoading(uploadManager.IsLoading);
    }, [uploadManager.IsLoading]);

    useEffect(() => {
        setLeadskey(prevState => {
            const newState = { ...prevState };
            const mapIds = new Set(Object.values(selectedRows)
                .map(item => item.mapId)
                .filter(Boolean));

            Object.keys(newState).forEach(key => {
                newState[key].isSelected = mapIds.has(key);
            });
            return newState;
        });
    }, [selectedRows]);

    const handleUploadFile = async (file) => {
        return await uploadManager.fetchFile(file);
    };

    const setMatchHeaderData = (data) => {
        uploadManager.setHeaderMapping(data);
    };

    const processImport = async (data) => {
        try {
            setLoading(true);
            const res = await uploadManager.setHeaderMapping(data);
            if (res) {
                const data = await uploadManager.validate();
                if (data) {
                    setLoading(false);
                    setTotalLeads({ exist: data.exist, new: data.new });
                    alert(`Data has been validated there are ${data.exist} existing data and ${data.new} new data do you want to rewrite existing file on your database?`);
                    fetchDataPerPage();
                }
            }
        } catch (error) {
            setLoading(false);
            throw new Error(error);
        }
    };

    const continueProcessImport = async () => {
        await uploadManager.continueProcessImport();
    };

    const handleCheckboxChange = (key, value, isChecked) => {
        if (isChecked) {
            setSelectedRows(prevState => ({
                ...prevState,
                [key]: value
            }));
        } else {
            setSelectedRows(prevState => {
                const newState = { ...prevState };
                delete newState[key];
                return newState;
            });
        }
    };

    const handleSelectedHeader = () => {
        if (Object.keys(selectedRows).length > 0) {
            setStep(3);
            setMatchHeaderData(selectedRows);
        } else {
            alert('Please select at least one header row');
        }
    };

    const submitMatchColumn = () => {
        const hasNonNullMapId = Object.values(selectedRows).some(item => item.mapId !== null);
        if (!hasNonNullMapId) {
            alert('Please select at least one column to match');
        } else {
            processImport(selectedRows);
            setStep(4);
        }
    };

    const handleMatchColumns = (e, headerName) => {
        e.preventDefault();
        setSelectedRows(prevState => {
            const newState = { ...prevState };
            for (const key in selectedRows) {
                if (selectedRows[key].headerName == headerName) {
                    selectedRows[key].mapId = e.target.value;
                    break;
                }
            }
            return newState;
        });
    };

    const onDragOver = useCallback((e) => {
        e.preventDefault();
        setIsDragging(true);
    }, []);

    const onDragLeave = useCallback((e) => {
        e.preventDefault();
        setIsDragging(false);
    }, []);

    const onDrop = useCallback(async (e) => {
        e.preventDefault();
        setIsDragging(false);
        const droppedFile = e.dataTransfer.files[0];
        if (droppedFile && (droppedFile.name.endsWith('.xlsx') || droppedFile.name.endsWith('.xls') || droppedFile.name.endsWith('.csv'))) {
            const data = await handleUploadFile(droppedFile);
            if (data) {
                setData(data);
                setStep(2);
                setLoading(false);
            }
        } else {
            alert('Please upload a .xlsx, .xls, or .csv file');
        }
    }, []);

    const handleFileChange = useCallback(async (e) => {
        try {
            const selectedFile = e.target.files[0];
            if (selectedFile && (selectedFile.name.endsWith('.xlsx') || selectedFile.name.endsWith('.xls') || selectedFile.name.endsWith('.csv'))) {
                setLoading(true);
                const data = await handleUploadFile(selectedFile);
                if (data) {
                    setData(data);
                    setStep(2);
                    setLoading(false);
                }
            } else {
                alert('Please select a .xlsx, .xls, or .csv file');
            }
        } catch (error) {
            setLoading(false);
            throw new Error(error);
        }
    }, []);

    const AddCustomHeader = () => {
        const newHeader = `Custom${Object.keys(selectedRows).length + 1}`;
        setSelectedRows(prevState => ({
            ...prevState,
            [newHeader]: {
                headerName: newHeader,
                mapId: null,
                custom: true
            }
        }));
    };

    const AddMatchColumnHeader = (headerName, newKey) => {
        setSelectedRows(prevState => {
            const newState = { ...prevState };
            for (const key in selectedRows) {
                if (selectedRows[key].headerName == headerName) {
                    selectedRows[key].mapId = newKey;
                    selectedRows[key].custom = true;
                    break;
                }
            }
            return newState;
        });
    };

    const AddNewHeader = (headerName, selection) => {

        switch (selection) {
            case "auto":
                setLeadskey(prevState => {
                    const newKey = headerName.toLowerCase().replace(/ /g, "_");
                    if (prevState.hasOwnProperty(newKey)) {
                        alert(`Header "${headerName}" already exists.`);
                        return prevState;
                    } else {
                        // Add the new header
                        setTimeout(() => alert('New header has been added.'), 0);
                        AddMatchColumnHeader(headerName, newKey);
                        return {
                            ...prevState,
                            [newKey]: {
                                isSelected: false,
                                key: newKey
                            }
                        };

                    }
                });
                setShowModal(false);
                break;
            case "custom":
                setLeadskey(prevState => {
                    const newKey = headerName.toLowerCase().replace(/ /g, "_");
                    if (prevState.hasOwnProperty(newKey)) {
                        alert(`Header "${headerName}" already exists.`);
                        return prevState;
                    } else {
                        // Add the new header
                        setTimeout(() => alert('New header has been added.'), 0);
                        return {
                            ...prevState,
                            [newKey]: {
                                isSelected: false,
                                key: newKey
                            }
                        };

                    }
                });
                setShowModal(false);
                break;
            default:
                break;
        }
    };

    const toggleModal = (flag = false) => {
        setShowModal(flag);
    };

    const fetchDataPerPage = async () => {
        const newData = await uploadManager.fetchDataPerPage();
        if (newData && newData.length > 0) {
            setLeadDataValidation(prevData => [...prevData, ...newData]);
        }
    };

    const clearData = () => {
        setData({});
        setSelectedRows({});
        setStep(1);
        setLeadDataValidation([]);
        setTotalLeads({});
    };

    const handleConfirmValidation = async () => {
        await continueProcessImport();
        clearData();
    };

    const back = () => {
        if(step == 1) return
        setStep(step - 1)
    };

    return (
        <UploadContext.Provider
            value={{
                totalLeads,
                isLoading,
                leadKeys,
                leadsDataValidation,
                isDragging,
                data,
                selectedRows,
                step,
                showModal,
                toggleModal,
                AddMatchColumnHeader,
                AddNewHeader,
                fetchDataPerPage,
                handleUploadFile,
                setMatchHeaderData,
                continueProcessImport,
                processImport,
                AddCustomHeader,
                AddMatchColumnHeader,
                handleCheckboxChange,
                handleFileChange,
                onDragOver,
                onDrop,
                onDragLeave,
                handleMatchColumns,
                submitMatchColumn,
                handleSelectedHeader,
                clearData,
                handleConfirmValidation,
                back
            }} >
            {children}
        </UploadContext.Provider>
    );
};

import React, { useRef, useEffect } from 'react';


export const DropdownMenu = ({ isOpen, onClose, anchorEl, options = [], onSelection }) => {
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !anchorEl.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onClose, anchorEl]);

    if (!isOpen) return null;

    const anchorRect = anchorEl.getBoundingClientRect();

    const styles = {
        dropdownContent: {
            position: 'absolute',
            top: `10px`,
            left: `${anchorRect.left - 10}px`,
            backgroundColor: '#f9f9f9',
            minWidth: '160px',
            boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
            zIndex: 1000,
            borderRadius: '4px',
            overflow: 'hidden',
        },
        dropdownItem: {
            color: 'black',
            padding: '12px 16px',
            textDecoration: 'none',
            display: 'block',
            cursor: 'pointer',
        },
    };

    return (
        <div style={styles.dropdownContent} ref={dropdownRef}>
            {options.map((item, index) => (
                <div
                    key={index}
                    style={styles.dropdownItem}
                    onClick={() => {
                        if (typeof onSelection === 'function') onSelection(item);
                        onClose();
                    }}
                >
                    {item}
                </div>
            ))}
        </div>
    );
};
import React from "react";
import { Oval } from "react-loader-spinner";
export function Loader({ height = "100vh" }) {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: height
            }}
        >
            <Oval
                visible={true}
                height="80"
                width="80"
                color="#1e1053"
                secondaryColor="#977ff5"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
            />
        </div>
    );
}

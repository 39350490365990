"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./LeadTableheader.module.css";

export function LeadTableheader({
  as: _Component = _Builtin.Block,
  name = "Name",
  organization = "Organization",
  website = "Website",
  address = "Address",
  city = "City",
  contact = "Contact",
  status = "Status",
}) {
  return (
    <_Component className={_utils.cx(_styles, "tablehd_container")} tag="div">
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-auto")}
        tag="div"
      >
        <_Builtin.FormCheckboxWrapper
          className={_utils.cx(_styles, "checkbox-field")}
        >
          <_Builtin.FormCheckboxInput
            className={_utils.cx(_styles, "checkbox")}
            type="checkbox"
            name="checkbox-5"
            data-name="Checkbox 5"
            required={false}
            checked={false}
            id="checkbox-5"
            form={{
              type: "checkbox-input",
              name: "Checkbox 5",
            }}
            inputType="custom"
            customClassName="w-checkbox-input--inputType-custom"
          />
          <_Builtin.FormInlineLabel
            className={_utils.cx(_styles, "checkbox-label")}
          >
            {"Checkbox"}
          </_Builtin.FormInlineLabel>
        </_Builtin.FormCheckboxWrapper>
      </_Builtin.Block>
      <_Builtin.Block className={_utils.cx(_styles, "tablecell")} tag="div">
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          {name}
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-15")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          {organization}
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-10")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          {website}
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-15")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          {address}
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-6")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          {city}
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-8")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          {contact}
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-20")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "tableheader_text")}
          tag="div"
        >
          {status}
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-8")}
        tag="div"
      />
    </_Component>
  );
}

"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./Rowvalidate.module.css";

export function Rowvalidate({
  as: _Component = _Builtin.Block,
  namefield = {},
  positionfield = {},
  orgfield = {},
  websitefield = {},
  addressfield = {},
  cityfield = {},
  contactfield = {},
  statusfield = {},
  row={},
  headerRow=[],
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "tablerow", "selectheaderrow")}
      tag="div"
    >
      {/* <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-auto")}
        tag="div"
      >
        <_Builtin.FormCheckboxWrapper
          className={_utils.cx(_styles, "checkbox-field")}
        >
          <_Builtin.FormCheckboxInput
            className={_utils.cx(_styles, "checkbox")}
            type="checkbox"
            name="checkbox-2"
            data-name="Checkbox 2"
            required={false}
            checked={false}
            id="checkbox-2"
            form={{
              type: "checkbox-input",
              name: "Checkbox 2",
            }}
            inputType="custom"
            customClassName="w-checkbox-input--inputType-custom"
          />
          <_Builtin.FormInlineLabel
            className={_utils.cx(_styles, "checkbox-label")}
          >
            {"Checkbox"}
          </_Builtin.FormInlineLabel>
        </_Builtin.FormCheckboxWrapper>
      </_Builtin.Block> */}
      {
       row && Object.entries(row).map(([key, value]) => 
          headerRow && headerRow.map((header, index) => {
              if(header.mapId == key){
                return( 
                  <_Builtin.Block className={_utils.cx(_styles, "tablecell", "_w-auto")} key={index} tag="div" >
                    <_Builtin.FormTextInput
                      className={_utils.cx(_styles, "validatetextfield")}
                      autoFocus={false}
                      maxLength={256}
                      name="field-8"
                      data-name="Field 8"
                      placeholder="Albert Flores"
                      type="text"
                      disabled={false}
                      required={true}
                      id="field-8"
                      defaultValue={value}
                    />
                  </_Builtin.Block>
                )
              }
        })
      )
      }
    </_Component>
  );
}

"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { Inboxitem } from "./Inboxitem";
import * as _utils from "./utils";
import _styles from "./Inboxcol1.module.css";

export function Inboxcol1({ as: _Component = _Builtin.Block, data = [], handleClickUserInbox = () => { }, currentLead = {} }) {
  return (
    <_Component className={_utils.cx(_styles, "dashboard-col1")} tag="div">
      <_Builtin.Block className={_utils.cx(_styles, "inbox-top")} tag="div">
        <_Builtin.Block
          className={_utils.cx(_styles, "phonetop_left")}
          tag="div"
        >
          <_Builtin.Heading
            className={_utils.cx(_styles, "phoneheader_text")}
            tag="h3"
          >
            {"Inbox"}
          </_Builtin.Heading>
        </_Builtin.Block>
        <_Builtin.Block
          className={_utils.cx(_styles, "phonetop_right")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "phonetop_iconbtn")}
            tag="div"
          >
            <_Builtin.Image
              className={_utils.cx(_styles, "image-100")}
              width="auto"
              height="auto"
              loading="lazy"
              alt=""
              src="images/66969177fb74722e775dde42_inbox_01.svg"
            />
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "phonetop_iconbtn")}
            tag="div"
          >
            <_Builtin.Image
              className={_utils.cx(_styles, "image-100")}
              width="auto"
              height="auto"
              loading="lazy"
              alt=""
              src="images/66968578d5889c78730e31ba_Icon_search.svg"
            />
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "phonetop_iconbtn")}
            tag="div"
          >
            <_Builtin.Image
              className={_utils.cx(_styles, "image-100")}
              width="auto"
              height="auto"
              loading="lazy"
              alt=""
              src="images/66969177ee1a6a8cbeae5e12_inbox_02.svg"
            />
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block className={_utils.cx(_styles, "inbox-contain-primary")} tag="div">
        {data.length !== 0 && data.map((item, index) => {
          if (item.primary)
            return (
              <Inboxitem key={index}
                currentLead={currentLead}
                item={item}
                handleClickUserInbox={handleClickUserInbox}
                {...item.baseInfo} />
            );
          return null;
        })}
      </_Builtin.Block>
      <_Builtin.Block className={_utils.cx(_styles, "inbox-contain")} tag="div">
        {data.length !== 0 && data.map((item, index) => {
          if (!item.primary)
            return (
              <Inboxitem key={index}
                currentLead={currentLead}
                item={item}
                handleClickUserInbox={handleClickUserInbox}
                {...item.baseInfo} />
            );
          return null;
        })}
      </_Builtin.Block>
    </_Component>
  );
}

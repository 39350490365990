"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./ValidatedataTableheader.module.css";

export function ValidatedataTableheader({
  as: _Component = _Builtin.Block,
  name = "Name",
  position = "Position",
  organization = "Organization",
  website = "Website",
  address = "Address",
  city = "City",
  contact = "Contact",
  status = "Status",
  selectedRows = {},
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "tablehd_container", "validatedata")}
      tag="div"
    >
      <_Builtin.Block
        className={_utils.cx(_styles, "tablecell", "_w-auto")}
        tag="div"
      >
        {/* <_Builtin.FormCheckboxWrapper
          className={_utils.cx(_styles, "checkbox-field")}
        >
          <_Builtin.FormCheckboxInput
            className={_utils.cx(_styles, "checkbox")}
            type="checkbox"
            name="checkbox-5"
            data-name="Checkbox 5"
            required={false}
            checked={false}
            id="checkbox-5"
            form={{
              type: "checkbox-input",
              name: "Checkbox 5",
            }}
            inputType="custom"
            customClassName="w-checkbox-input--inputType-custom"
          />
          <_Builtin.FormInlineLabel
            className={_utils.cx(_styles, "checkbox-label")}
          >
            {"Checkbox"}
          </_Builtin.FormInlineLabel>
        </_Builtin.FormCheckboxWrapper> */}
      </_Builtin.Block>
      {selectedRows && Object.values(selectedRows).map((key, index) => (
      <_Builtin.Block key={index} className={_utils.cx(_styles, "tablecell")} tag="div">
        <_Builtin.Block tag="div">{key.mapId}</_Builtin.Block>
      </_Builtin.Block>
      ))
      }
    </_Component>
  );
}

"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./Transcriptioninbound.module.css";
import moment from "moment";

export function Transcriptioninbound({
  as: _Component = _Builtin.Block,
  transcripttext = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  timestamp = "00:10",
}) {
  return (
    <_Component
      className={_utils.cx(_styles, "bubblecontainer_inbound-mainwrap")}
      tag="div"
    >
      <_Builtin.Block
        className={_utils.cx(_styles, "bubblecontainer_inbound-wrap")}
        tag="div"
      >
        <_Builtin.Block
          className={_utils.cx(_styles, "bubblecontainer_inbound")}
          tag="div"
        >
          <_Builtin.Block
            className={_utils.cx(_styles, "messagestatus_div", "mb-5")}
            tag="div"
          >
            <_Builtin.Block tag="div">{moment(timestamp).format("HH:mm")}</_Builtin.Block>
          </_Builtin.Block>
          <_Builtin.Block
            className={_utils.cx(_styles, "bubble_cr_inbound")}
            tag="div"
          >
            <_Builtin.Block tag="div">{transcripttext}</_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}

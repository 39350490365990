import Utilities from "../common/Utilities";
import { Device } from '@twilio/voice-sdk';
import { EventEmitter } from "events";

import { STATUS } from "../common/const";

export const USER_STATUS = {
    ONLINE: "online",
    OFFLINE: "offline",
    BUSY: "busy",
};

export default class CallManager extends EventEmitter {
    #device = null;
    #wss = null;
    #connection = null;
    #userId = null;
    #status = STATUS.DEFAULT;
    #info = {};
    #loading = false;
    #token = null;
    #debounce = null;
    #infos = {};
    #messageId = null;
    #inputDevices = new Set();
    #outputDevices = new Set();
    #agentStatus = USER_STATUS.OFFLINE;
    constructor(wss) {
        super();
        this.#wss = wss;
        this.setMaxListeners(10);
    }
    /**
     * @returns {import("../services/websocket").default}
     */
    get Instance() {
        return this.#wss;
    }
    /**
     * @returns {Device}
     */
    get Device() {
        return this.#device;
    }
    get Status() {
        return this.#status;
    }
    get Info() {
        return this.#info;
    }
    get IsLoading() {
        return this.#loading;
    }
    get InputDevices() {
        let retval = [];
        this.#inputDevices.forEach(device => {
            retval.push(device);
        });
        return retval;
    }
    get OutputDevices() {
        let retval = [];
        this.#outputDevices.forEach(device => {
            retval.push(device);
        });
        return retval;
    }
    get AgentStatus() {
        return this.#agentStatus;
    }
    setUserId(userId) {
        this.#userId = userId;
        this.#wss.callFunc("updateAgentStatus", { userId: this.#userId, status: this.#agentStatus });
    }
    setLoading(value) {
        this.emit("ready", value);
    }
    createInfo({ currentNumber, callFrom, textNumber, messageId, inboxId }) {
        const info = {};
        if (currentNumber)
            info.currentNumber = currentNumber;
        if (callFrom)
            info.callFrom = callFrom;
        if (textNumber)
            info.textNumber = textNumber;
        if (messageId)
            info.messageId = messageId;
        if (inboxId)
            info.inboxId = inboxId;
        info.avatarImage = "images/avatar_01.png";
        this.#infos[messageId] = info;
    }
    init(userId) {
        if (!this.#token) {
            this.setLoading(true);
            this.Instance.callFunc("getToken", userId).then((token) => {
                if (token) {
                    this.#token = token;
                    this.#device = new Device(token, { codecPreferences: ['opus', 'pcmu'] });
                    this.listen(userId);
                    this.Device.register();
                    this.getAudioDevices();
                }
            }).catch((error) => {
                console.error("Failed to get token", error);
            }).finally(() => {
                this.setLoading(false);
            });
        }
    }
    handleIncomingCall(call, isOutbound = false) {
        this.#connection = call;
        /**
         * @type {Map}
         */
        const customParameters = call.customParameters;
        let messageId = customParameters.get("messageId");
        if (isOutbound) {
            this.#messageId = call.parameters.CallSid;
        } else {
            this.#info = this.#infos[messageId];
            this.#status = STATUS.INCOMING;
            this.emit("status", { status: this.#status, info: this.#info });
        }
        call.on("cancel", (e) => {
            console.log("Call cancelled", e);
            this.#status = STATUS.DEFAULT;
            this.emit("status", { status: this.#status, info: this.#info });
            this.onAgentStatusChange({ target: { value: USER_STATUS.ONLINE } });
        });
        call.on("disconnect", (e) => {
            console.log("Call disconnected", e);
            this.#status = STATUS.DEFAULT;
            this.emit("status", { status: this.#status, info: this.#info });
            this.setLoading(false);
            this.onAgentStatusChange({ target: { value: USER_STATUS.ONLINE } });
        });
        call.on("reject", (e) => {
            console.log("Call rejected", e);
            this.#status = STATUS.DEFAULT;
            this.emit("status", { status: this.#status, info: this.#info });
            this.onAgentStatusChange({ target: { value: USER_STATUS.ONLINE } });
        });
        call.on("accept", (e) => {
            console.log("Call accepted", e);
            this.#status = STATUS.ONCALL;
            this.emit("status", { status: STATUS.ONCALL, info: this.#info });
            this.setLoading(false);
            this.onAgentStatusChange({ target: { value: USER_STATUS.BUSY } });
        });
    }
    listen(userId) {
        this.Device.on("error", (error) => {
            console.error("Twilio Device Error:", error);
        });
        this.Device.on("unregistered", () => {
            console.info("Twilio unregistered");
        });
        this.Device.on("registered", () => {
            console.info("Twilio registered");
            this.#userId = userId;
            this.setLoading(false);
        });
        this.Device.on("tokenWillExpire", () => {
            console.info("Twilio token will expire");
            this.#token = null;
            this.init(this.#userId);
        });
        this.Device.on("incoming", (call) => {
            this.handleIncomingCall(call);
        });
        this.#wss.subscribe("call", (data) => {
            const message = data.message;
            this.createInfo({
                callFrom: message.callFrom,
                textNumber: message.phoneNumber,
                currentNumber: message.currentNumber,
                messageId: message.messageId,
                direction: message.direction,
                inboxId: message.inboxId,
            });
            if (message.direction === "inbound") {
                switch (message.status) {
                    default:
                        this.#status = STATUS.DEFAULT;
                        this.#loading = false;
                        break;
                }
            } else if (message.direction === "outbound") {
                switch (message.status) {
                    case "ringing":
                        // this.emit("outbound", message);
                        this.#status = STATUS.ONCALL;
                        break;
                    case "in-progress":
                        this.#status = STATUS.ONCALL;
                        break;
                    case "completed":
                        this.#status = STATUS.DEFAULT;
                        this.#loading = false;
                        break;
                    default:
                        this.#loading = false;
                        break;
                }
            }
            if (this.#debounce) clearTimeout(this.#debounce);
            this.#debounce = setTimeout(() => {
                this.emit("status", { status: this.#status, info: this.#info });
                this.setLoading(this.#loading);
            }, 1000);
        });
    }
    accept() {
        if (this.#connection) this.#connection.accept();
    }
    reject() {
        if (this.#connection) {
            console.log("Rejecting call", this.#connection);
            if (this.#info && this.#info.direction === "inbound")
                this.#connection.reject();
            else
                this.#connection.disconnect();
        }
        const messageId = this.#info ? this.#info.messageId : null;
        if (messageId) {
            this.#wss.callFunc("hangUp", { messageId });
        }
        this.#status = STATUS.DEFAULT;
        this.emit("status", { status: STATUS.DEFAULT, info: {} });
    }
    makeCall(number) {
        let errorMsg = null;
        const phone = Utilities.numberValidator(number);
        if (!this.#device) errorMsg = "Device not initialized";
        if (!this.#userId) errorMsg = "User not initialized";
        if (!phone.isValid) errorMsg = "Invalid number";
        if (!errorMsg) {
            const params = { To: phone.e164Format, userId: this.#userId };
            this.setLoading(true);
            this.Device.connect({ params }).then((call) => {
                // this.setLoading(false);
                this.handleIncomingCall(call, true);
            }).catch((error) => {
                console.error("Failed to make call", error);
                this.setLoading(false);
                this.#status = STATUS.ERROR;
                this.emit("status", { status: STATUS.ERROR, err: error.message || "Failed to make call" });
            });
        } else {
            this.#status = STATUS.ERROR;
            this.emit("status", { status: STATUS.ERROR, err: errorMsg });
            setTimeout(() => {
                this.#status = STATUS.DEFAULT;
                this.emit("status", { status: STATUS.DEFAULT, err: "" });
            }, 1000 * 2);
        }
    }
    async getAudioDevices() {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        if (this.#device) {
            this.#device.audio.availableInputDevices.forEach(device => {
                if (!this.#inputDevices.has(device.deviceId)) this.#inputDevices.add(device);
            });
            this.#device.audio.availableOutputDevices.forEach(device => {
                if (!this.#outputDevices.has(device.deviceId)) this.#outputDevices.add(device);
            });
        }
        this.emit("audioDevices", { input: this.InputDevices, output: this.OutputDevices });
    }
    setOutputDevice(deviceId) {
        if (this.#device) {
            this.#device.audio.speakerDevices.set(deviceId);
        }
    }
    setInputDevice(deviceId) {
        if (this.#device) {
            this.#device.audio.setInputDevice(deviceId);
        }
    }
    updateOutputDevice(event) {
        if (event) {
            const type = event.target.name;
            const value = event.target.value;
            switch (type) {
                case "inputDevices":
                    if (this.#device) {
                        this.#device.audio.availableInputDevices.forEach(device => {
                            if (device.deviceId === value) {
                                this.setInputDevice(device.deviceId);
                            }
                        });
                    }
                    break;
                case "outputDevices":
                    if (this.#device) {
                        this.#device.audio.availableOutputDevices.forEach(device => {
                            if (device.deviceId === value) {
                                this.setOutputDevice(device.deviceId);
                            }
                        });
                    }
                    break;
                default:
                    break;
            }
        }
    }
    onAgentStatusChange(event) {
        const values = Object.values(USER_STATUS);
        switch (event.target.value) {
            case values[0]:
                this.#agentStatus = USER_STATUS.ONLINE;
                if (!this.Device) this.init(this.#userId);
                break;
            case values[1]:
                this.#agentStatus = USER_STATUS.OFFLINE;
                break;
            case values[2]:
                this.#agentStatus = USER_STATUS.BUSY;
                break;
            default:
                this.#agentStatus = USER_STATUS.OFFLINE;
                if (this.Device)
                    this.Device.destroy();
                this.#device = null;

                break;
        }
        this.emit("agentStatus", this.#agentStatus);
        this.#wss.callFunc("updateAgentStatus", { userId: this.#userId, status: this.#agentStatus });
    }
    sendDigits(digit) {
        if (this.#connection) this.#connection.sendDigits(digit);
    }
}
"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import * as _utils from "./utils";
import _styles from "./LeadinfoItem.module.css";

export function LeadinfoItem({
  as: _Component = _Builtin.Block,
  label = "Work Email",
  data = "",
}) {
  return (
    <_Component className={_utils.cx(_styles, "leadinfo_item")} tag="div">
      <_Builtin.Paragraph className={_utils.cx(_styles, "p-style1", "mb-5")}>
        {label}
      </_Builtin.Paragraph>
      <_Builtin.Paragraph
        className={_utils.cx(_styles, "p-style1", "text-bold")}
      >
        {data}
      </_Builtin.Paragraph>
    </_Component>
  );
}

"use client";
import React, { useEffect, useRef, useState, useCallback } from "react";
import * as _Builtin from "./_Builtin";
import { LeadTableheader } from "./LeadTableheader";
import { LeadTablerow } from "./LeadTablerow";
import { SectionLeadinfo } from "./SectionLeadinfo";
import { Controldiv } from "./Controldiv";
import * as _utils from "./utils";
import _styles from "./Leads.module.css";
import { Loader } from "../component/common/Loader";
import { LoaderChat } from "../component/common/LoaderChat";

export function Leads({ as: _Component = _Builtin.Block, dialerHandler = () => { }, callStatus = "", handleHangUp = () => { }, leadsData = [],
  leadTableLoading = false, history = [], historyLoading = false, handlerRowClick = () => { }, handleHistoryClick = () => { },
  interLeadsLoading = false, handleClickInterLeads = () => { }, interDataLeads = [], transLeadData = [], transLeadsLoading = false,
  leadBaseInfo = {}, loadMoreLeads = () => { }, isLeadsLoadingMore = false, loadMoreInter = () => { }, isLoadingMore = false, callErrorMsg = "",
  openModalKeypad = () => { }
}) {

  const [openRowIndex, setOpenRowIndex] = useState(null);
  const scrollRef = useRef(null);

  const handleLeadsRowClick = (index) => {
    handlerRowClick(leadsData[index]);
    setOpenRowIndex(prevIndex => prevIndex === index ? null : index);
  };

  const handleScroll = useCallback(() => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 20) {
        loadMoreLeads();
      }
    }
  }, []);

  useEffect(() => {
    const currentScrollRef = scrollRef.current;
    if (currentScrollRef) {
      currentScrollRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentScrollRef) {
        currentScrollRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);


  return (
    <_Component className={_utils.cx(_styles, "leads-container")} tag="div">
      <_Builtin.TabsWrapper
        className={_utils.cx(_styles, "convo-tabs")}
        current="Tab 1"
        easing="ease"
        fadeIn={300}
        fadeOut={100}
      >
        <_Builtin.TabsMenu
          className={_utils.cx(_styles, "convo-tabsmenu", "leads")}
          tag="div"
        >
          <_Builtin.TabsLink
            className={_utils.cx(_styles, "convo-tablink")}
            data-w-tab="Tab 1"
            block="inline"
          >
            <_Builtin.Block tag="div">{"Open"}</_Builtin.Block>
          </_Builtin.TabsLink>
          <_Builtin.TabsLink
            className={_utils.cx(_styles, "convo-tablink")}
            data-w-tab="Tab 2"
            block="inline"
          >
            <_Builtin.Block tag="div">{"Called"}</_Builtin.Block>
          </_Builtin.TabsLink>
          <_Builtin.TabsLink
            className={_utils.cx(_styles, "convo-tablink")}
            data-w-tab="Tab 3"
            block="inline"
          >
            <_Builtin.Block tag="div">{"Follow-up"}</_Builtin.Block>
          </_Builtin.TabsLink>
          <_Builtin.TabsLink
            className={_utils.cx(_styles, "convo-tablink")}
            data-w-tab="Tab 4"
            block="inline"
          >
            <_Builtin.Block tag="div">{"Cold Leads"}</_Builtin.Block>
          </_Builtin.TabsLink>
        </_Builtin.TabsMenu>
        <_Builtin.TabsContent
          className={_utils.cx(_styles, "convo-tabscontent", "noborder")}
          tag="div"
        >
          <_Builtin.TabsPane
            className={_utils.cx(_styles, "tabpane")}
            tag="div"
            data-w-tab="Tab 1"
          >
            <_Builtin.FormWrapper className={_utils.cx(_styles, "form-block")}>
              <_Builtin.FormForm
                name="email-form"
                data-name="Email Form"
                method="get"
                id="email-form"
              >
                <_Builtin.Block
                  className={_utils.cx(_styles, "leads_body")}
                  tag="div"
                  ref={scrollRef}
                >
                  <LeadTableheader />
                  {leadTableLoading && leadsData.length === 0 ? <Loader /> : <_Builtin.Block
                    className={_utils.cx(_styles, "tablerow_container")}
                    tag="div"
                  >
                    {leadsData.length !== 0 && leadsData.map((lead, index) => (
                      <React.Fragment key={index}>
                        <LeadTablerow
                          lead={lead}
                          handleLeadsRowClick={() => handleLeadsRowClick(index)}
                        />
                        {openRowIndex === index && (
                          <SectionLeadinfo
                            dialerHandler={dialerHandler}
                            callStatus={callStatus}
                            handleHangUp={handleHangUp}
                            lead={lead}
                            isRowClicked={openRowIndex === index}
                            history={history}
                            historyLoading={historyLoading}
                            handleHistoryClick={handleHistoryClick}
                            interDataLeads={interDataLeads}
                            interLeadsLoading={interLeadsLoading}
                            transLeadData={transLeadData}
                            transLeadsLoading={transLeadsLoading}
                            handleClickInterLeads={handleClickInterLeads}
                            leadBaseInfo={leadBaseInfo}
                            loadMoreInter={loadMoreInter}
                            isLoadingMore={isLoadingMore}
                            callErrorMsg={callErrorMsg}
                            openModalKeypad={openModalKeypad}
                          />
                        )}
                      </React.Fragment>
                    ))}
                    {isLeadsLoadingMore && <LoaderChat />}
                  </_Builtin.Block>}
                </_Builtin.Block>
              </_Builtin.FormForm>
              <_Builtin.FormSuccessMessage>
                <_Builtin.Block tag="div">
                  {"Thank you! Your submission has been received!"}
                </_Builtin.Block>
              </_Builtin.FormSuccessMessage>
              <_Builtin.FormErrorMessage>
                <_Builtin.Block tag="div">
                  {"Oops! Something went wrong while submitting the form."}
                </_Builtin.Block>
              </_Builtin.FormErrorMessage>
            </_Builtin.FormWrapper>
          </_Builtin.TabsPane>
          <_Builtin.TabsPane tag="div" data-w-tab="Tab 2" />
          <_Builtin.TabsPane tag="div" data-w-tab="Tab 3" />
          <_Builtin.TabsPane tag="div" data-w-tab="Tab 4" />
        </_Builtin.TabsContent>
      </_Builtin.TabsWrapper>
      <Controldiv />
    </_Component>
  );
}
